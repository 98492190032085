// src/components/FormSection3_WorkExperience.js

import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from "react";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  Work as WorkIcon,
  Business as BusinessIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";
import { FormContext } from "./FormContext";
import { SubsectionHeader, FormField } from "./FormComponents";

// Import the new FormSectionSubHeader component
import FormSectionSubHeader from "./FormSectionSubHeader";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    padding: theme.spacing(2), // Increased padding for consistency
    borderRadius: theme.shape.borderRadius,
  },
  formContent: {
    position: "relative",
  },
  workExperienceEntries: {
    marginTop: theme.spacing(4),
  },
  workExperienceLevelButtons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: "600px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  workExperienceLevelButton: {
    flex: "1 1 auto",
    padding: theme.spacing(1, 2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    transition: "all 0.3s",
    minWidth: "100px",
    maxWidth: "150px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      fontSize: "0.875rem",
      minWidth: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "200px",
    },
  },
  workExperienceLevelTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  entryContainer: {
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  subsection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  currentlyEmployedCheckbox: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dateField: {
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      color: theme.palette.primary.main,
    },
  },
}));

// Initial work experience entry
const initialWorkExperience = {
  jobTitle: "",
  companyName: "",
  location: "",
  startDate: "",
  endDate: "",
  currentlyEmployed: false,
  jobDescriptionAndAchievements: "",
  supervisorName: "",
  supervisorContact: "",
  reasonForLeaving: "",
  skillsUtilized: "",
  technologiesUsed: "",
};

// WorkExperienceEntry Component
const WorkExperienceEntry = memo(
  ({
    index,
    experience,
    handleChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    const isCurrentlyEmployed = experience.currentlyEmployed;

    const formFields = [
      {
        section: "Job Details",
        fields: [
          {
            name: "jobTitle",
            label: "Job Title",
            required: true,
            icon: WorkIcon,
            fullWidth: true,
          },
          {
            name: "companyName",
            label: "Company Name",
            required: true,
            icon: BusinessIcon,
            fullWidth: true,
          },
          {
            name: "location",
            label: "Location (City, State)",
            required: true,
            fullWidth: true,
          },
          {
            name: "startDate",
            label: "Start Date",
            type: "date",
            required: true,
            helperText: "Enter the date you started this job",
            InputLabelProps: { shrink: true }, // Prevent label overlap
            className: classes.dateField, // Apply custom class
            placeholder: "mm/dd/yyyy",
          },
          {
            name: "endDate",
            label: "End Date",
            type: "date",
            required: !isCurrentlyEmployed,
            hidden: isCurrentlyEmployed,
            helperText: "Enter the date you left this job",
            InputLabelProps: { shrink: true }, // Prevent label overlap
            className: classes.dateField, // Apply custom class
            placeholder: "mm/dd/yyyy",
          },
        ],
      },
      {
        section: "Responsibilities and Achievements",
        fields: [
          {
            name: "jobDescriptionAndAchievements",
            label: "Job Description and Key Achievements",
            required: true,
            multiline: true,
            minRows: 10,
            fullWidth: true,
            maxLength: 4000,
          },
        ],
      },
      {
        section: "Additional Information",
        fields: [
          {
            name: "supervisorName",
            label: "Supervisor Name",
          },
          {
            name: "supervisorContact",
            label: "Supervisor Contact",
          },
          {
            name: "reasonForLeaving",
            label: "Reason for Leaving",
            multiline: true,
            minRows: 2,
            fullWidth: true,
          },
          {
            name: "skillsUtilized",
            label: "Skills Utilized",
            fullWidth: true,
          },
          {
            name: "technologiesUsed",
            label: "Technologies Used",
            fullWidth: true,
          },
        ],
      },
    ];

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader title={`Work Experience ${index + 1}`} />
        {formFields.map(({ section, fields }) => (
          <React.Fragment key={section}>
            <SubsectionHeader title={section} className={classes.subsection} />
            <Grid container spacing={3} className={classes.formSection}>
              {fields.map(
                (field) =>
                  !field.hidden && (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={experience[field.name] || ""}
                      onChange={(name, value) =>
                        handleChange(index, name, value)
                      }
                      error={errors[field.name]}
                      tooltip={`Enter the ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      rows={field.rows}
                      maxLength={field.maxLength}
                      select={field.select}
                      options={field.options}
                      inputProps={field.inputProps}
                      helperText={field.helperText}
                      InputLabelProps={
                        field.type === "date"
                          ? field.InputLabelProps
                          : undefined
                      }
                      className={
                        field.type === "date" ? field.className : undefined
                      }
                      placeholder={
                        field.type === "date" ? field.placeholder : undefined
                      }
                    >
                      {field.select &&
                        field.options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </FormField>
                  )
              )}
            </Grid>
            {section === "Job Details" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCurrentlyEmployed}
                    onChange={(e) =>
                      handleChange(index, "currentlyEmployed", e.target.checked)
                    }
                    name="currentlyEmployed"
                    color="primary"
                  />
                }
                label="I am currently employed in this position"
                className={classes.currentlyEmployedCheckbox}
              />
            )}
          </React.Fragment>
        ))}
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this work experience">
            <IconButton
              onClick={() => handleRemoveEntry(index)}
              color="secondary"
              aria-label="Remove work experience"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another work experience">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add work experience"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

// Named Export
export const WorkExperience = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);

  const auth = useAuth(); // Get the authenticated user

  // Ref to store previous form data
  const prevFormDataRef = useRef();

  // Handle field changes with validation
  const handleChange = useCallback(
    (index, name, value) => {
      const updatedWorkExperience = [...formData.workExperience];
      updatedWorkExperience[index] = {
        ...updatedWorkExperience[index],
        [name]: value,
      };
      updateFormData("workExperience", updatedWorkExperience);

      // Placeholder for validation logic
      // Implement validateField as needed
      // const fieldError = validateField(name, value, formData);
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [index]: {
      //     ...prevErrors[index],
      //     [name]: fieldError,
      //   },
      // }));
    },
    [formData.workExperience, updateFormData]
  );

  const handleAddEntry = useCallback(() => {
    updateFormData("workExperience", [
      ...formData.workExperience,
      initialWorkExperience,
    ]);
  }, [formData.workExperience, updateFormData]);

  const handleRemoveEntry = useCallback(
    (index) => {
      const updatedWorkExperience = formData.workExperience.filter(
        (_, i) => i !== index
      );
      updateFormData("workExperience", updatedWorkExperience);
      if (updatedWorkExperience.length === 0) {
        updateFormData("noWorkExperience", true);
      }
    },
    [formData.workExperience, updateFormData]
  );

  const handleNoExperienceChange = useCallback(
    (event) => {
      const noExperience = event.target.checked;
      updateFormData("noWorkExperience", noExperience);
      if (noExperience) {
        updateFormData("workExperience", []);
      } else if (formData.workExperience.length === 0) {
        updateFormData("workExperience", [initialWorkExperience]);
      }
    },
    [formData.workExperience, updateFormData]
  );

  // Debounced auto-save function to prevent excessive writes
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  // Save data to localStorage and Firestore
  const saveData = useCallback(
    async (data) => {
      // Save to localStorage
      localStorage.setItem("workExperience", JSON.stringify(data));

      // If user is authenticated, save to Firestore
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        try {
          // Use setDoc with merge: true
          await setDoc(userDocRef, { workExperience: data }, { merge: true });
        } catch (error) {
          console.error("Error saving data:", error);
          throw error; // Re-throw to handle in debouncedAutoSave
        }
      }
    },
    [auth.user]
  );

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("workExperience");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("workExperience", parsedData);
        } else if (auth.user) {
          // Fetch from Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data().workExperience;
            if (data) {
              updateFormData("workExperience", data);
              // Optionally, save to localStorage
              localStorage.setItem("workExperience", JSON.stringify(data));
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  // Auto-save whenever workExperience data changes
  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.workExperience;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.workExperience, debouncedAutoSave, loading]);

  return (
    <Paper elevation={3} className={classes.formPaper}>
      {/* Replace the main h6 header and divider with FormSectionSubHeader */}
      <FormSectionSubHeader title="Work Experience:" />
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <div className={classes.formInnerContent}>
            <div className={classes.workExperienceEntries}>
              {!formData.noWorkExperience &&
                formData.workExperience.map((experience, index) => (
                  <WorkExperienceEntry
                    key={index}
                    index={index}
                    experience={experience}
                    handleChange={handleChange}
                    handleRemoveEntry={handleRemoveEntry}
                    handleAddEntry={handleAddEntry}
                    errors={errors[index] || {}}
                  />
                ))}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.noWorkExperience || false}
                  onChange={handleNoExperienceChange}
                  name="noWorkExperience"
                  color="primary"
                />
              }
              label="I have no work experience"
              className={classes.currentlyEmployedCheckbox}
            />
          </div>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert} // Apply custom styling
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

// Default Export
export default WorkExperience;
