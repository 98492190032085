import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Divider,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import {
  AccountBalanceWallet as WalletIcon,
  TrendingDown as TrendingDownIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { db } from "../util/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { FormContext } from "./FormContext";
import { Skeleton } from "@material-ui/lab";
import { CSSTransition } from "react-transition-group";

/**
 * makeStyles hook for styling (Material-UI v4).
 * Adjust these classes as desired for a more compact, modern look.
 */
const useStyles = makeStyles((theme) => ({
  // Global animations for fade in/out using react-transition-group
  "@global": {
    ".fade-enter": {
      opacity: 0,
      transform: "translateY(10px)",
    },
    ".fade-enter-active": {
      opacity: 1,
      transform: "translateY(0)",
      transition: "opacity 0.3s, transform 0.3s",
    },
    ".fade-exit": {
      opacity: 1,
      transform: "translateY(0)",
    },
    ".fade-exit-active": {
      opacity: 0,
      transform: "translateY(10px)",
      transition: "opacity 0.3s, transform 0.3s",
    },
  },

  cardWrapper: {
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  card: {
    borderRadius: 12,
    boxShadow: theme.shadows[3],
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
    textAlign: "center",
  },
  planTitle: {
    fontWeight: 700,
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  explanation: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  creditsRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1.5),
  },
  creditsInfo: {
    display: "flex",
    alignItems: "center",
  },
  creditsIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "1.4rem",
  },
  creditsText: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  buttonWrapper: {
    display: "inline-block",
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  button: {
    minWidth: 120,
    height: 42,
    borderRadius: 22,
    textTransform: "none",
    fontSize: "0.95rem",
    fontWeight: 600,
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  toggleInfoRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  toggleIcon: {
    marginLeft: theme.spacing(0.5),
    transition: "transform 0.3s",
  },
  toggleIconExpanded: {
    transform: "rotate(180deg)",
  },
  accordionContent: {
    marginTop: theme.spacing(1),
  },
}));

/**
 * A refined, compact display for showing user generation credits,
 * plan information, and remaining credits after selections.
 * Includes animations, skeleton loading, and an expandable info section.
 */
const GenerationCreditsDisplay = () => {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { formData } = useContext(FormContext);

  const [userData, setUserData] = useState(null);
  const [expanded, setExpanded] = useState(false);

  /**
   * Fetches user doc from Firestore and subscribes to updates.
   * If no user is logged in, userData remains null.
   */
  useEffect(() => {
    if (!auth.user) {
      setUserData(null);
      return;
    }
    const userDocRef = doc(db, "users", auth.user.uid);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      setUserData(docSnapshot.exists() ? docSnapshot.data() : null);
    });
    return unsubscribe;
  }, [auth.user]);

  /**
   * Toggles the expanded state for the plan info section.
   */
  const handleToggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  /**
   * Safely extracts `credits` and `planId` from Firestore user data.
   * Defaults to 0 credits and "Free" plan if not present.
   */
  const { credits: userCredits = 0, planId = "Free" } = userData || {};

  /**
   * Calculates the total number of toolkit options the user has selected,
   * to estimate how many credits will remain after usage.
   */
  const totalToolkitSelections = (formData.targetJobInformation || []).reduce(
    (total, job) =>
      total + Object.values(job.generationOptions || {}).filter(Boolean).length,
    0
  );

  /**
   * Determines the approximate remaining credits after the user selections.
   */
  const estimatedCreditsRemaining = Math.max(
    userCredits - totalToolkitSelections,
    0
  );

  return (
    <div className={classes.cardWrapper}>
      {/* Fade in/out animation on mount/unmount */}
      <CSSTransition in appear timeout={300} classNames="fade">
        <Card className={classes.card}>
          <div className={classes.header}>
            <Typography className={classes.planTitle}>
              {userData
                ? `${planId.charAt(0).toUpperCase() + planId.slice(1)} Plan`
                : "Loading Plan..."}
            </Typography>
          </div>

          <CardContent className={classes.content}>
            {/* If userData isn't loaded, show skeleton placeholders */}
            {!userData && (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rect" height={40} style={{ marginTop: 8 }} />
              </>
            )}

            {/* Once userData is loaded, display the credits information */}
            {userData && (
              <>
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.explanation}
                >
                  Available credits and estimated remainder after your current
                  selections:
                </Typography>

                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={6} className={classes.creditsRow}>
                    <Tooltip title="Your total available generation credits.">
                      <div className={classes.creditsInfo}>
                        <WalletIcon className={classes.creditsIcon} />
                        <Typography className={classes.creditsText}>
                          Credits: {userCredits}
                        </Typography>
                      </div>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={6} className={classes.creditsRow}>
                    <Tooltip title="Estimated credits left after applying your selections.">
                      <div className={classes.creditsInfo}>
                        <TrendingDownIcon className={classes.creditsIcon} />
                        <Typography className={classes.creditsText}>
                          After Selections: {estimatedCreditsRemaining}
                        </Typography>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>

                {/* If credits are at zero, show an upgrade button */}
                {userCredits === 0 && (
                  <>
                    <Divider className={classes.divider} />
                    <Grid container justifyContent="center">
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            router.push("https://www.resumerevival.xyz/pricing")
                          }
                          className={classes.button}
                          aria-label="Upgrade Plan"
                        >
                          <Typography
                            variant="button"
                            className={classes.buttonText}
                          >
                            Upgrade Plan
                          </Typography>
                        </Button>
                      </div>
                    </Grid>
                  </>
                )}

                {/* Toggle button for additional plan info */}
                <div
                  className={classes.toggleInfoRow}
                  onClick={handleToggleExpand}
                >
                  <Typography variant="subtitle2">
                    {expanded ? "Hide" : "More"} Plan Info
                  </Typography>
                  <ExpandMoreIcon
                    className={`${classes.toggleIcon} ${
                      expanded ? classes.toggleIconExpanded : ""
                    }`}
                  />
                </div>

                {/* Collapsible section with plan details */}
                <Collapse in={expanded}>
                  <div className={classes.accordionContent}>
                    <Typography variant="body2" color="textSecondary">
                      On the {planId.charAt(0).toUpperCase() + planId.slice(1)}{" "}
                      Plan, you have a limited number of credits for generating
                      materials. Once you run out, consider upgrading to
                      increase your available credits and continue generating
                      content.
                    </Typography>
                  </div>
                </Collapse>
              </>
            )}
          </CardContent>
        </Card>
      </CSSTransition>
    </div>
  );
};

export default GenerationCreditsDisplay;
