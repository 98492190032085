// src/components/GenerationsList.js

import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  Checkbox,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Paper,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
  Delete as DeleteIcon,
  CalendarToday as CalendarTodayIcon,
  Clear as ClearIcon,
  Star as StarIcon,
  StarBorder,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useRouter } from "../util/router";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../util/firebase";
import GenerationsCalendar from "./GenerationsCalendar";
import applicationMaterials from "./applicationMaterials";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getLogoUrl } from "../util/helpers";

const useStyles = makeStyles((theme) => ({
  searchFieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  searchField: {
    flexGrow: 1,
    minWidth: 200,
  },
  calendarIconButton: {
    marginLeft: theme.spacing(1),
  },
  generationListContainer: {
    position: "relative",
    maxHeight: 720,
  },
  generationList: {
    position: "relative",
  },
  listItem: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.selected,
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filtersContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  statsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function parseTimestamp(timestamp) {
  if (timestamp instanceof Date) return timestamp;
  if (timestamp && typeof timestamp.toDate === "function")
    return timestamp.toDate();
  if (typeof timestamp === "string") {
    let str = timestamp.replace(" at ", " ");
    str = str.replace(/\u202F/g, " ");
    str = str.replace(/UTC[\+\-]\d+/, "");
    return new Date(str);
  }
  return new Date();
}

function parseSalary(salaryStr) {
  if (!salaryStr || typeof salaryStr !== "string") return null;
  try {
    salaryStr = salaryStr.replace(/[^\d\-–,]/g, "").trim();
    if (salaryStr.includes("-") || salaryStr.includes("–")) {
      const delimiter = salaryStr.includes("-") ? "-" : "–";
      const [minStr, maxStr] = salaryStr.split(delimiter);
      const min = parseInt(minStr.replace(/,/g, ""));
      const max = parseInt(maxStr.replace(/,/g, ""));
      if (!isNaN(min) && !isNaN(max)) return (min + max) / 2;
    }
    const salary = parseInt(salaryStr.replace(/,/g, ""));
    if (!isNaN(salary)) return salary;
    return null;
  } catch {
    return null;
  }
}

function hasMaterial(generation, key) {
  if (generation[key + "Json"]) return true;
  if (generation.content && generation.content[key + "Json"]) return true;
  if (
    generation.content &&
    Array.isArray(generation.content.items) &&
    generation.content.items.length > 0 &&
    generation.content.items[0][key + "Json"]
  ) {
    return true;
  }
  return false;
}

function GenerationsList({
  auth,
  generations,
  setGenerations,
  loadingGenerations,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGenerations, setExpandedGenerations] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeletionMode, setIsDeletionMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [sortOption, setSortOption] = useState("dateDesc");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterActive, setFilterActive] = useState(true);
  const [filterClosed, setFilterClosed] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [filterInterview, setFilterInterview] = useState(true);
  const [filterOffer, setFilterOffer] = useState(true);
  const [filterAccepted, setFilterAccepted] = useState(true);
  const [favorites, setFavorites] = useState([]);

  const toggleFavorite = useCallback((id) => {
    setFavorites((prev) =>
      prev.includes(id) ? prev.filter((fid) => fid !== id) : [...prev, id]
    );
  }, []);

  const [debouncedSearch, setDebouncedSearch] = useState(searchQuery);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleDeleteGeneration = useCallback(
    async (generationId) => {
      if (auth?.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        try {
          await deleteDoc(generationDocRef);
          setSnackbar({
            open: true,
            message: "Generation deleted successfully.",
            severity: "success",
          });
          setGenerations((prevGenerations) =>
            prevGenerations.filter((gen) => gen.id !== generationId)
          );
        } catch (error) {
          console.error("Error deleting generation from Firestore:", error);
          setSnackbar({
            open: true,
            message: "Failed to delete generation.",
            severity: "error",
          });
        }
      }
    },
    [auth?.user, setGenerations]
  );

  const handleDeleteSelectedGenerations = useCallback(async () => {
    await Promise.all(
      selectedForDeletion.map((id) => handleDeleteGeneration(id))
    );
    setDeleteDialogOpen(false);
    setIsDeletionMode(false);
    setSelectedForDeletion([]);
  }, [selectedForDeletion, handleDeleteGeneration]);

  const handleGenerationClick = (generationId) => {
    setExpandedGenerations((prevExpanded) =>
      prevExpanded.includes(generationId)
        ? prevExpanded.filter((id) => id !== generationId)
        : [...prevExpanded, generationId]
    );
  };

  const handleToggleSelection = (id) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const matchesStatusFilters = useCallback(
    (generation) => {
      const { progress = 0, status = "active" } = generation;
      if (status === "closed" && !filterClosed) return false;
      if (status !== "closed" && !filterActive) return false;
      if (progress === 0 && !filterApplied) return false;
      if (progress === 1 && !filterInterview) return false;
      if (progress === 2 && !filterOffer) return false;
      if (progress === 3 && !filterAccepted) return false;
      return true;
    },
    [
      filterActive,
      filterClosed,
      filterApplied,
      filterInterview,
      filterOffer,
      filterAccepted,
    ]
  );

  const filteredGenerations = useMemo(() => {
    let result = generations;
    if (debouncedSearch) {
      const query = debouncedSearch.toLowerCase();
      result = result.filter(
        (generation) =>
          (generation.companyName &&
            generation.companyName.toLowerCase().includes(query)) ||
          (generation.jobTitle &&
            generation.jobTitle.toLowerCase().includes(query)) ||
          (generation.jobDetails?.jobDescription &&
            generation.jobDetails.jobDescription.toLowerCase().includes(query))
      );
    }
    result = result.filter((gen) => matchesStatusFilters(gen));
    // Favorited items come first
    result.sort((a, b) => {
      const aFav = favorites.includes(a.id);
      const bFav = favorites.includes(b.id);
      if (aFav && !bFav) return -1;
      if (!aFav && bFav) return 1;
      return 0;
    });
    // Then sort by selected sort option
    result.sort((a, b) => {
      if (sortOption === "salaryAsc" || sortOption === "salaryDesc") {
        const salaryA = parseSalary(a.jobDetails?.salary || "");
        const salaryB = parseSalary(b.jobDetails?.salary || "");
        if (salaryA === null && salaryB === null) return 0;
        if (salaryA === null) return 1;
        if (salaryB === null) return -1;
        return sortOption === "salaryAsc"
          ? salaryA - salaryB
          : salaryB - salaryA;
      } else if (sortOption === "companyAsc" || sortOption === "companyDesc") {
        const companyA = a.companyName?.toLowerCase() || "";
        const companyB = b.companyName?.toLowerCase() || "";
        if (companyA < companyB) return sortOption === "companyAsc" ? -1 : 1;
        if (companyA > companyB) return sortOption === "companyAsc" ? 1 : -1;
        return 0;
      } else {
        const dateA = parseTimestamp(a.timestamp);
        const dateB = parseTimestamp(b.timestamp);
        return sortOption === "dateAsc" ? dateA - dateB : dateB - dateA;
      }
    });
    return result;
  }, [
    generations,
    debouncedSearch,
    sortOption,
    favorites,
    matchesStatusFilters,
  ]);

  const stats = useMemo(() => {
    const total = filteredGenerations.length;
    const activeCount = filteredGenerations.filter(
      (ev) => ev.status !== "closed"
    ).length;
    const closedCount = filteredGenerations.filter(
      (ev) => ev.status === "closed"
    ).length;
    const appliedCount = filteredGenerations.filter(
      (ev) => ev.progress === 0
    ).length;
    const interviewsCount = filteredGenerations.filter(
      (ev) => ev.progress === 1
    ).length;
    const offersCount = filteredGenerations.filter(
      (ev) => ev.progress === 2
    ).length;
    const acceptedCount = filteredGenerations.filter(
      (ev) => ev.progress === 3
    ).length;
    return {
      total,
      activeCount,
      closedCount,
      appliedCount,
      interviewsCount,
      offersCount,
      acceptedCount,
    };
  }, [filteredGenerations]);

  const clearAllFilters = () => {
    setSearchQuery("");
    setSortOption("dateDesc");
    setFilterActive(true);
    setFilterClosed(true);
    setFilterApplied(true);
    setFilterInterview(true);
    setFilterOffer(true);
    setFilterAccepted(true);
  };

  const handleEditGeneration = useCallback(
    async (generationId, updatedData) => {
      if (!auth?.user) return;
      const generationDocRef = doc(
        db,
        "users",
        auth.user.uid,
        "generations",
        generationId
      );
      try {
        await updateDoc(generationDocRef, updatedData);
        setGenerations((prevGenerations) =>
          prevGenerations.map((gen) =>
            gen.id === generationId ? { ...gen, ...updatedData } : gen
          )
        );
        setSnackbar({
          open: true,
          message: "Generation rescheduled successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating generation in Firestore:", error);
        setSnackbar({
          open: true,
          message: "Failed to reschedule generation.",
          severity: "error",
        });
      }
    },
    [auth?.user, setGenerations]
  );

  const handleUpdateProgress = useCallback(
    async (generationId, progress, status) => {
      if (!auth?.user) {
        console.warn("No authenticated user found, cannot update progress.");
        return;
      }
      const generationDocRef = doc(
        db,
        "users",
        auth.user.uid,
        "generations",
        generationId
      );
      try {
        await updateDoc(generationDocRef, { progress, status });
        setGenerations((prevGenerations) =>
          prevGenerations.map((gen) =>
            gen.id === generationId ? { ...gen, progress, status } : gen
          )
        );
        setSnackbar({
          open: true,
          message: "Progress updated successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating progress in Firestore:", error);
        setSnackbar({
          open: true,
          message: "Failed to update progress.",
          severity: "error",
        });
      }
    },
    [auth?.user, setGenerations]
  );

  const handleGenerationSelect = useCallback(
    (generationId, materialKey) => {
      localStorage.setItem("selectedGenerationId", generationId);
      if (materialKey) {
        localStorage.setItem("selectedApplicationMaterial", materialKey);
      } else {
        localStorage.removeItem("selectedApplicationMaterial");
      }
      router.push("/pro");
    },
    [router]
  );

  return (
    <Box mt={5}>
      <Typography variant="h6" gutterBottom>
        Your Generated Applications
      </Typography>
      <div className={classes.statsContainer}>
        <Typography variant="body2" color="textSecondary">
          Total: {stats.total}, Active: {stats.activeCount}, Not Selected:{" "}
          {stats.closedCount}, Applied: {stats.appliedCount}, Interview:{" "}
          {stats.interviewsCount}, Offer: {stats.offersCount}, Accepted:{" "}
          {stats.acceptedCount}
        </Typography>
      </div>
      <Box className={classes.searchFieldContainer}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          placeholder="Search (Company, Title, Description)"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" aria-label="search icon" />
              </InputAdornment>
            ),
            "aria-label": "Search Generations",
          }}
        />
        {searchQuery && (
          <Tooltip title="Clear Search">
            <IconButton
              onClick={() => setSearchQuery("")}
              aria-label="Clear search"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
        <FormControl variant="outlined" size="small">
          <InputLabel id="sort-by-label">Sort By</InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            label="Sort By"
            aria-label="Sort Generations"
          >
            <MenuItem value="dateDesc">Date (Newest)</MenuItem>
            <MenuItem value="dateAsc">Date (Oldest)</MenuItem>
            <MenuItem value="companyAsc">Company (A-Z)</MenuItem>
            <MenuItem value="companyDesc">Company (Z-A)</MenuItem>
            <MenuItem value="salaryDesc">Salary (High to Low)</MenuItem>
            <MenuItem value="salaryAsc">Salary (Low to High)</MenuItem>
          </Select>
        </FormControl>
        <Box
          display="flex"
          alignItems="center"
          onClick={() => setFiltersOpen(!filtersOpen)}
          style={{ cursor: "pointer" }}
          aria-label="Toggle filters"
        >
          <FilterListIcon style={{ marginLeft: theme.spacing(1) }} />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginLeft: theme.spacing(0.5) }}
          >
            {filtersOpen ? "Hide Filters" : "Show Filters"}
          </Typography>
          {filtersOpen ? (
            <ExpandLess style={{ marginLeft: theme.spacing(1) }} />
          ) : (
            <ExpandMore style={{ marginLeft: theme.spacing(1) }} />
          )}
        </Box>
        <Tooltip title="Open Calendar" aria-label="Open Calendar">
          <IconButton
            className={classes.calendarIconButton}
            onClick={() => setCalendarOpen(true)}
            aria-label="View Calendar"
            color="primary"
          >
            <CalendarTodayIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={filtersOpen}>
        <Paper className={classes.filtersContainer}>
          <Typography variant="subtitle1" gutterBottom>
            Status & Progress Filters
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterActive}
                  onChange={(e) => setFilterActive(e.target.checked)}
                  color="primary"
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterClosed}
                  onChange={(e) => setFilterClosed(e.target.checked)}
                  color="primary"
                />
              }
              label="Not Selected"
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterApplied}
                  onChange={(e) => setFilterApplied(e.target.checked)}
                  color="primary"
                />
              }
              label="Applied"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterInterview}
                  onChange={(e) => setFilterInterview(e.target.checked)}
                  color="primary"
                />
              }
              label="Interview"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOffer}
                  onChange={(e) => setFilterOffer(e.target.checked)}
                  color="primary"
                />
              }
              label="Offer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterAccepted}
                  onChange={(e) => setFilterAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label="Accepted"
            />
          </FormGroup>
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button
              startIcon={<ClearIcon />}
              onClick={clearAllFilters}
              size="small"
            >
              Clear Filters
            </Button>
          </Box>
        </Paper>
      </Collapse>
      {loadingGenerations ? (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress aria-label="Loading" />
        </Box>
      ) : generations.length > 0 ? (
        <>
          <PerfectScrollbar className={classes.generationListContainer}>
            <List className={classes.generationList}>
              {filteredGenerations.map((generation) => {
                const isExpanded = expandedGenerations.includes(generation.id);
                const isFavorite = favorites.includes(generation.id);
                const materialsAvailable = Object.keys(
                  applicationMaterials
                ).filter((key) => hasMaterial(generation, key));
                return (
                  <React.Fragment key={generation.id}>
                    <ListItem
                      button
                      onClick={() => {
                        if (isDeletionMode) {
                          handleToggleSelection(generation.id);
                        } else {
                          handleGenerationClick(generation.id);
                        }
                      }}
                      className={classes.listItem}
                      aria-expanded={isExpanded}
                      aria-label={`Generation for ${generation.companyName} as ${generation.jobTitle}`}
                    >
                      {isDeletionMode && (
                        <Checkbox
                          edge="start"
                          checked={selectedForDeletion.includes(generation.id)}
                          tabIndex={-1}
                          disableRipple
                          onChange={() => handleToggleSelection(generation.id)}
                          color="primary"
                          inputProps={{
                            "aria-label": `Select generation for ${generation.companyName}`,
                          }}
                        />
                      )}
                      <ListItemIcon className={classes.listItemAvatar}>
                        <Avatar
                          src={getLogoUrl(generation.companyName)}
                          alt={`${generation.companyName} Logo`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "";
                          }}
                        ></Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={generation.companyName || "Unknown Company"}
                        secondary={generation.jobTitle || "Unknown Title"}
                      />
                      {!isDeletionMode && (
                        <>
                          <Tooltip
                            title="Toggle Favorite"
                            aria-label="Toggle Favorite"
                          >
                            <IconButton
                              edge="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFavorite(generation.id);
                              }}
                            >
                              {isFavorite ? (
                                <StarIcon style={{ color: "#FFD700" }} />
                              ) : (
                                <StarBorder />
                              )}
                            </IconButton>
                          </Tooltip>

                          {/*
                            ------------------------------------------------------------------
                            SINGLE-ITEM DELETE ICON
                            ------------------------------------------------------------------
                          */}
                          <Tooltip
                            title="Delete This Generation"
                            aria-label="Delete This Generation"
                          >
                            <IconButton
                              edge="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedForDeletion([generation.id]);
                                setDeleteDialogOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>

                          {isExpanded ? (
                            <ExpandLess aria-label="Collapse details" />
                          ) : (
                            <ExpandMore aria-label="Expand details" />
                          )}
                        </>
                      )}
                    </ListItem>
                    <Collapse
                      in={isExpanded && !isDeletionMode}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {materialsAvailable.length > 0 ? (
                          materialsAvailable.map((key) => {
                            const MaterialIcon = applicationMaterials[key].icon;
                            return (
                              <ListItem
                                button
                                key={key}
                                className={classes.nestedListItem}
                                onClick={() => {
                                  handleGenerationSelect(generation.id, key);
                                }}
                                aria-label={`Select ${applicationMaterials[key].title}`}
                              >
                                <ListItemIcon>
                                  <MaterialIcon aria-hidden="true" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={applicationMaterials[key].title}
                                />
                              </ListItem>
                            );
                          })
                        ) : (
                          <ListItem className={classes.nestedListItem}>
                            <ListItemText primary="No application materials available for this generation." />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </PerfectScrollbar>
          {filteredGenerations.length === 0 && (
            <Box p={3}>
              <Typography variant="body1" color="textSecondary">
                No job generations match your search or filters.
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <Box p={3}>
          <Typography variant="body1" color="textSecondary">
            You haven't generated any application materials yet.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Why not create one now?
          </Typography>
        </Box>
      )}
      {generations.length > 0 && (
        <Box display="flex" justifyContent="flex-end" mt={3}>
          {isDeletionMode ? (
            <>
              <Button
                onClick={() => {
                  setIsDeletionMode(false);
                  setSelectedForDeletion([]);
                }}
                style={{ marginRight: theme.spacing(2) }}
                aria-label="Cancel deletion mode"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (selectedForDeletion.length > 0) {
                    setDeleteDialogOpen(true);
                  }
                }}
                color="secondary"
                variant="contained"
                aria-label="Delete selected generations"
                disabled={selectedForDeletion.length === 0}
              >
                Delete
              </Button>
            </>
          ) : (
            <Tooltip title="Delete Multiple" aria-label="Delete Multiple">
              <IconButton
                onClick={() => {
                  setIsDeletionMode(true);
                  setSelectedForDeletion([]);
                }}
                aria-label="Delete generations"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle
          id="delete-confirmation-dialog"
          className={classes.dialogTitle}
        >
          Delete Job Materials
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the selected job materials?
          </Typography>
          <List>
            {selectedForDeletion.map((id) => {
              const generation = generations.find((gen) => gen.id === id);
              return (
                <ListItem key={id}>
                  <ListItemText
                    primary={generation?.companyName || "Unknown Company"}
                    secondary={generation?.jobTitle || "Unknown Title"}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="primary"
            autoFocus
            aria-label="Cancel deletion"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteSelectedGenerations}
            color="secondary"
            variant="contained"
            aria-label="Confirm deletion"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="generations-calendar-dialog"
      >
        <DialogTitle
          id="generations-calendar-dialog"
          className={classes.dialogTitle}
        >
          Application Materials Calendar
        </DialogTitle>
        <DialogContent>
          {loadingGenerations ? (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress aria-label="Loading calendar" />
            </Box>
          ) : (
            <GenerationsCalendar
              generations={generations}
              onSelectGeneration={handleGenerationSelect}
              onEditGeneration={handleEditGeneration}
              onDeleteGeneration={handleDeleteGeneration}
              onUpdateProgress={handleUpdateProgress}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar((prev) => ({
            ...prev,
            open: false,
          }))
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() =>
            setSnackbar((prev) => ({
              ...prev,
              open: false,
            }))
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default GenerationsList;
