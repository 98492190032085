/**
 * Resume.js
 *
 * A dynamic resume builder component that supports editing personal information,
 * work experiences, education, skills, and additional qualifications (including
 * volunteer experience, military service, certifications, and personal projects).
 *
 * Toggle between edit mode and preview mode.
 */

import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  Person as PersonIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  Star as StarIcon,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DownloadButton from "./DownloadButton";
import PreviewResume from "./PreviewResume";

/**
 * updateNestedObject
 * Utility function to safely update deeply nested properties in state
 *
 * @param {object|array} obj       The original object or array
 * @param {string[]} pathArray     An array of keys or indices describing the path
 * @param {any} value             The new value to set at that path
 * @returns {object|array}        A new object or array with the updated value
 */
const updateNestedObject = (obj, pathArray, value) => {
  if (pathArray.length === 0) return value;
  const [firstKey, ...restKeys] = pathArray;

  // If we're dealing with an array
  if (Array.isArray(obj)) {
    const index = Number(firstKey);
    // If for some reason the index is invalid, just return the array
    if (isNaN(index) || index < 0 || index >= obj.length) {
      return obj;
    }

    // If this is the final key, replace the element directly
    if (restKeys.length === 0) {
      const newArr = [...obj];
      newArr[index] = value;
      return newArr;
    }

    // Otherwise, recursively update the array element
    const newArr = [...obj];
    newArr[index] = updateNestedObject(obj[index], restKeys, value);
    return newArr;
  }

  // If we're dealing with an object
  if (restKeys.length === 0) {
    return { ...obj, [firstKey]: value };
  }

  return {
    ...obj,
    [firstKey]: updateNestedObject(obj[firstKey] || {}, restKeys, value),
  };
};

/**
 * A reusable text field with a tooltip, controlling its value from props.
 */
const EditableField = function EditableField({
  label,
  tooltip,
  value,
  onChange,
  ...props
}) {
  return (
    <Tooltip title={tooltip}>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
    </Tooltip>
  );
};

/**
 * A small helper component for date fields, to reduce repetition in the code.
 */
const DateField = function DateField({
  label,
  tooltip,
  value,
  onChange,
  disabled,
}) {
  return (
    <Tooltip title={tooltip}>
      <TextField
        label={label}
        variant="outlined"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        disabled={disabled}
      />
    </Tooltip>
  );
};

/**
 * A reusable section component for arrays of items (work experience, education, etc.)
 */
const ArraySection = function ArraySection({
  title,
  icon: Icon,
  items,
  onAdd,
  onRemove,
  children,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.sectionContainer}>
      <Typography variant="h5" className={classes.sectionTitle}>
        <Box display="flex" alignItems="center">
          <Icon color="primary" style={{ marginRight: theme.spacing(1) }} />
          {title}
        </Box>
      </Typography>
      {items.map((item, index) => (
        <Box key={index}>
          {index > 0 && <Divider className={classes.divider} />}
          <Box className={classes.arrayItemHeader}>
            <Typography variant="h6">
              {title.slice(0, -1)} {index + 1}
            </Typography>
            <IconButton onClick={() => onRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
          {children(index, item)}
        </Box>
      ))}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={onAdd}
        variant="outlined"
        color="primary"
        style={{ marginTop: theme.spacing(2) }}
      >
        Add {title.slice(0, -1)}
      </Button>
    </Box>
  );
};

/**
 * Renders form fields for a specific "qualification" type (Military, Certification, Project, Volunteer).
 */
const QualificationForm = function QualificationForm({
  qualification,
  index,
  onChange,
  onRemove,
  previewMode,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { qualificationType, details } = qualification;

  const handleDetailChange = (field, value) => {
    onChange({ ...qualification, details: { ...details, [field]: value } });
  };

  const renderVolunteerFields = () => (
    <Grid container spacing={2}>
      {["organizationName", "role"].map((field) => (
        <Grid item xs={12} sm={6} key={field}>
          <EditableField
            label={field
              .replace(/^\w/, (c) => c.toUpperCase())
              .replace(/([A-Z])/g, " $1")}
            tooltip={`Enter your ${field
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()}`}
            value={details[field]}
            onChange={(val) => handleDetailChange(field, val)}
            disabled={previewMode}
          />
        </Grid>
      ))}
      {["startDate", "endDate"].map((field) => (
        <Grid item xs={12} sm={6} key={field}>
          <DateField
            label={field.replace(/([A-Z])/g, " $1")}
            tooltip={`Select the ${field
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()}`}
            value={details[field]}
            onChange={(val) => handleDetailChange(field, val)}
            disabled={previewMode}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <EditableField
          label="Description"
          tooltip="Briefly describe your volunteer work"
          value={details.description}
          onChange={(val) => handleDetailChange("description", val)}
          disabled={previewMode}
          multiline
          minRows={3}
        />
      </Grid>
      <Grid item xs={12}>
        <EditableField
          label="Skills Utilized"
          tooltip="List skills used or developed, separated by commas"
          value={details.skills ? details.skills.join(", ") : ""}
          onChange={(val) =>
            handleDetailChange(
              "skills",
              val.split(",").map((s) => s.trim())
            )
          }
          disabled={previewMode}
        />
      </Grid>
      <Grid item xs={12}>
        <EditableField
          label="Achievements/Outcome"
          tooltip="Describe achievements or impact of your volunteer experience"
          value={details.outcome}
          onChange={(val) => handleDetailChange("outcome", val)}
          disabled={previewMode}
          multiline
          minRows={2}
        />
      </Grid>
    </Grid>
  );

  const renderFormFields = () => {
    switch (qualificationType) {
      case "Military Service":
        return (
          <Grid container spacing={2}>
            {["branch", "rank"].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <EditableField
                  label={field.replace(/^\w/, (c) => c.toUpperCase())}
                  tooltip={`Enter your ${field
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={details[field]}
                  onChange={(val) => handleDetailChange(field, val)}
                  disabled={previewMode}
                />
              </Grid>
            ))}
            {["startDate", "endDate"].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <DateField
                  label={field.replace(/([A-Z])/g, " $1")}
                  tooltip={`Select the ${field
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={details[field]}
                  onChange={(val) => handleDetailChange(field, val)}
                  disabled={previewMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <EditableField
                label="Special Training or Skills"
                tooltip="Enter any special training or skills acquired"
                value={details.specialTraining}
                onChange={(val) => handleDetailChange("specialTraining", val)}
                disabled={previewMode}
                multiline
                minRows={2}
              />
            </Grid>
          </Grid>
        );
      case "Certification":
        return (
          <Grid container spacing={2}>
            {["credentialType", "name", "issuer"].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <EditableField
                  label={field
                    .replace(/^\w/, (c) => c.toUpperCase())
                    .replace(/([A-Z])/g, " $1")}
                  tooltip={`Enter your ${field
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={details[field]}
                  onChange={(val) => handleDetailChange(field, val)}
                  disabled={previewMode}
                />
              </Grid>
            ))}
            {["dateObtained", "expirationDate"].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <DateField
                  label={field.replace(/([A-Z])/g, " $1")}
                  tooltip={`Select the ${field
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={details[field]}
                  onChange={(val) => handleDetailChange(field, val)}
                  disabled={previewMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <EditableField
                label="Description"
                tooltip="Briefly describe the certification and its relevance"
                value={details.description}
                onChange={(val) => handleDetailChange("description", val)}
                disabled={previewMode}
                multiline
                minRows={2}
              />
            </Grid>
          </Grid>
        );
      case "Personal Project":
        return (
          <Grid container spacing={2}>
            {["projectName", "projectUrl"].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <EditableField
                  label={field.replace(/([A-Z])/g, " $1")}
                  tooltip={`Enter your ${field
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={details[field]}
                  onChange={(val) => handleDetailChange(field, val)}
                  disabled={previewMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <EditableField
                label="Description"
                tooltip="Briefly describe the project’s goal and process"
                value={details.description}
                onChange={(val) => handleDetailChange("description", val)}
                disabled={previewMode}
                multiline
                minRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableField
                label="Skills Utilized"
                tooltip="List the skills used, separated by commas"
                value={details.skills ? details.skills.join(", ") : ""}
                onChange={(val) =>
                  handleDetailChange(
                    "skills",
                    val.split(",").map((s) => s.trim())
                  )
                }
                disabled={previewMode}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableField
                label="Project Outcome"
                tooltip="Describe the results or what you learned"
                value={details.outcome}
                onChange={(val) => handleDetailChange("outcome", val)}
                disabled={previewMode}
                multiline
                minRows={2}
              />
            </Grid>
          </Grid>
        );
      case "Volunteer Experience":
        return renderVolunteerFields();
      default:
        return (
          <Typography>
            Unknown qualification type: {qualificationType}
          </Typography>
        );
    }
  };

  return <Box>{renderFormFields()}</Box>;
};

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  editableField: { marginBottom: theme.spacing(2) },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  bulletList: {
    margin: 0,
    paddingLeft: theme.spacing(4),
  },
  bulletItem: {
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "outside",
    paddingLeft: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  fieldLabel: { fontWeight: "bold", marginBottom: theme.spacing(0.5) },
  arrayItemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  previewText: {
    marginBottom: theme.spacing(1),
    whiteSpace: "pre-wrap",
  },
}));

/**
 * The main Resume component, which assembles all sections (personal, work, etc.)
 */
const Resume = function Resume({
  content,
  onContentChange,
  handlePrintResume,
  downloading,
  keywords = [],
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [previewMode, setPreviewMode] = useState(false);

  // 1) Always define your Hooks at the top:
  const handleFieldChange = useCallback(
    (path, value) => {
      const updatedContent = updateNestedObject(content, path, value);
      onContentChange(updatedContent);
    },
    [content, onContentChange]
  );

  const handleAddArrayItem = useCallback(
    (path, newItem) => {
      const currentArray = path.reduce((acc, key) => acc[key], content) || [];
      const updatedArray = [...currentArray, newItem];
      handleFieldChange(path, updatedArray);
    },
    [content, handleFieldChange]
  );

  const handleRemoveArrayItem = useCallback(
    (path, indexToRemove) => {
      const currentArray = path.reduce((acc, key) => acc[key], content) || [];
      const updatedArray = currentArray.filter((_, i) => i !== indexToRemove);
      handleFieldChange(path, updatedArray);
    },
    [content, handleFieldChange]
  );

  // 2) Then conditionally render the error. Note that the Hooks have already been called above.
  if (!content || typeof content !== "object") {
    return <Typography>Invalid resume data</Typography>;
  }

  // Destructure relevant fields from content
  const {
    personalInformation: {
      firstName,
      lastName,
      email,
      phone,
      address,
      linkedinUrl,
      githubUrl,
      portfolioUrl,
      professionalSummary,
    } = {},
    workExperience = [],
    education = [],
    skills = [],
    additionalQualifications = [],
  } = content;

  // Convert array of skills to CSV string
  const skillsStr = skills.filter((s) => s).join(", ");

  // If in preview mode, show the "PreviewResume" component instead of all forms
  if (previewMode) {
    return (
      <Box>
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <FormControlLabel
            control={
              <Switch
                checked={previewMode}
                onChange={() => setPreviewMode(!previewMode)}
                color="primary"
              />
            }
            label="Preview Mode"
          />
        </Box>
        <PreviewResume content={content} keywords={keywords} />
        <Box className={classes.buttonGroup}>
          <DownloadButton
            onClick={() =>
              handlePrintResume(
                content,
                "resume_template_1.docx",
                "resume.docx"
              )
            }
            downloading={downloading}
            label="Download Resume"
            startIcon={<GetAppIcon />}
          />
        </Box>
      </Box>
    );
  }

  // If not in preview mode, render the edit forms
  return (
    <Box>
      {/* Toggle for preview mode */}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <FormControlLabel
          control={
            <Switch
              checked={previewMode}
              onChange={() => setPreviewMode(!previewMode)}
              color="primary"
            />
          }
          label="Preview Mode"
        />
      </Box>

      {/* Personal Information */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <PersonIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Personal Information
          </Box>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <EditableField
              label="First Name"
              tooltip="Enter your given name"
              placeholder="e.g. John"
              value={firstName}
              onChange={(val) =>
                handleFieldChange(["personalInformation", "firstName"], val)
              }
              multiline
              minRows={1}
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableField
              label="Last Name"
              tooltip="Enter your family name"
              placeholder="e.g. Doe"
              value={lastName}
              onChange={(val) =>
                handleFieldChange(["personalInformation", "lastName"], val)
              }
              multiline
              minRows={1}
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableField
              label="Email"
              tooltip="Enter your email address"
              placeholder="e.g. john.doe@example.com"
              value={email}
              onChange={(val) =>
                handleFieldChange(["personalInformation", "email"], val)
              }
              multiline
              minRows={1}
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EditableField
              label="Phone"
              tooltip="Enter your contact number"
              placeholder="e.g. (123) 456-7890"
              value={phone}
              onChange={(val) =>
                handleFieldChange(["personalInformation", "phone"], val)
              }
              multiline
              minRows={1}
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <EditableField
              label="Address"
              tooltip="Enter your full mailing address"
              placeholder="e.g. 123 Main St, Springfield, USA"
              value={address}
              onChange={(val) =>
                handleFieldChange(["personalInformation", "address"], val)
              }
              multiline
              minRows={2}
              maxRows={6}
            />
          </Grid>
          {["linkedinUrl", "githubUrl", "portfolioUrl"].map((field) => (
            <Grid item xs={12} key={field}>
              <EditableField
                label={field
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (c) => c.toUpperCase())}
                tooltip={`Enter your ${field
                  .replace(/([A-Z])/g, " $1")
                  .toLowerCase()} (optional)`}
                placeholder={`https://${field}.com/yourprofile`}
                value={content.personalInformation[field]}
                onChange={(val) =>
                  handleFieldChange(["personalInformation", field], val)
                }
                multiline
                minRows={1}
                maxRows={4}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Professional Summary */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Professional Summary
        </Typography>
        <EditableField
          tooltip="Summarize your career, key strengths, and what makes you stand out"
          placeholder="Write a short summary highlighting your key skills and experience"
          value={professionalSummary}
          onChange={(val) =>
            handleFieldChange(
              ["personalInformation", "professionalSummary"],
              val
            )
          }
          multiline
          minRows={3}
          maxRows={10}
        />
      </Box>

      {/* Work Experience */}
      {workExperience.length > 0 && (
        <ArraySection
          title="Work Experiences"
          icon={WorkIcon}
          items={workExperience}
          onAdd={() =>
            handleAddArrayItem(["workExperience"], {
              jobTitle: "",
              companyName: "",
              location: "",
              startDate: "",
              endDate: "",
              responsibilities: [""],
            })
          }
          onRemove={(index) => handleRemoveArrayItem(["workExperience"], index)}
        >
          {(index, job) => (
            <Box>
              <Grid container spacing={2}>
                {["jobTitle", "companyName", "location"].map((field) => (
                  <Grid item xs={12} sm={6} key={field}>
                    <EditableField
                      label={field
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (c) => c.toUpperCase())}
                      placeholder={`e.g. ${
                        field === "jobTitle"
                          ? "Software Engineer"
                          : field === "companyName"
                          ? "ABC Corp"
                          : "New York, NY"
                      }`}
                      value={job[field]}
                      onChange={(val) =>
                        handleFieldChange(["workExperience", index, field], val)
                      }
                      multiline
                      minRows={1}
                      maxRows={4}
                    />
                  </Grid>
                ))}
                {["startDate", "endDate"].map((field) => (
                  <Grid item xs={12} sm={3} key={field}>
                    <DateField
                      label={field.replace(/([A-Z])/g, " $1")}
                      tooltip={`Select the ${field
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()} of your employment`}
                      value={job[field]}
                      onChange={(val) =>
                        handleFieldChange(["workExperience", index, field], val)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              {/* Responsibilities */}
              <Typography className={classes.fieldLabel}>
                Responsibilities:
              </Typography>
              <List className={classes.bulletList}>
                {job.responsibilities.map((resp, idx) => (
                  <ListItem key={idx} className={classes.bulletItem}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={11}>
                        <EditableField
                          placeholder="Describe a key responsibility or achievement"
                          value={resp}
                          onChange={(val) =>
                            handleFieldChange(
                              [
                                "workExperience",
                                index,
                                "responsibilities",
                                idx,
                              ],
                              val
                            )
                          }
                          multiline
                          minRows={1}
                          maxRows={6}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() =>
                            handleRemoveArrayItem(
                              ["workExperience", index, "responsibilities"],
                              idx
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() =>
                  handleAddArrayItem(
                    ["workExperience", index, "responsibilities"],
                    ""
                  )
                }
                variant="outlined"
                color="primary"
                style={{ marginTop: theme.spacing(1) }}
              >
                Add Responsibility
              </Button>
            </Box>
          )}
        </ArraySection>
      )}

      {/* Education */}
      {education.length > 0 && (
        <ArraySection
          title="Educations"
          icon={SchoolIcon}
          items={education}
          onAdd={() =>
            handleAddArrayItem(["education"], {
              degree: "",
              fieldOfStudy: "",
              minor: "",
              institution: "",
              location: "",
              graduationDate: "",
            })
          }
          onRemove={(index) => handleRemoveArrayItem(["education"], index)}
        >
          {(index, edu) => (
            <Grid container spacing={2}>
              {[
                "degree",
                "fieldOfStudy",
                "minor",
                "institution",
                "location",
              ].map((field) => (
                <Grid item xs={12} sm={6} key={field}>
                  <EditableField
                    label={field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (c) => c.toUpperCase())}
                    placeholder={`e.g. ${
                      field === "degree"
                        ? "Bachelor of Science"
                        : field === "fieldOfStudy"
                        ? "Computer Science"
                        : field === "minor"
                        ? "Mathematics (optional)"
                        : field === "institution"
                        ? "University of XYZ"
                        : "Boston, MA"
                    }`}
                    value={edu[field]}
                    onChange={(val) =>
                      handleFieldChange(["education", index, field], val)
                    }
                    multiline
                    minRows={1}
                    maxRows={4}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={6}>
                <DateField
                  label="Graduation Date"
                  tooltip="Select your graduation date"
                  value={edu.graduationDate}
                  onChange={(val) =>
                    handleFieldChange(
                      ["education", index, "graduationDate"],
                      val
                    )
                  }
                />
              </Grid>
            </Grid>
          )}
        </ArraySection>
      )}

      {/* Skills */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <StarIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Skills
          </Box>
        </Typography>
        <EditableField
          tooltip="List your skills, separated by commas"
          placeholder="e.g. JavaScript, React, Node.js"
          value={skillsStr}
          onChange={(val) =>
            handleFieldChange(
              ["skills"],
              val.split(",").map((s) => s.trim())
            )
          }
          multiline
          minRows={1}
          maxRows={4}
        />
      </Box>

      {/* Additional Qualifications */}
      {additionalQualifications.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Additional Qualifications
          </Typography>
          {additionalQualifications.map((qualification, index) => (
            <Box key={index}>
              {index > 0 && <Divider className={classes.divider} />}
              <Box className={classes.arrayItemHeader}>
                <Typography variant="h6" className={classes.fieldLabel}>
                  {qualification.qualificationType}
                </Typography>
                <IconButton
                  onClick={() =>
                    handleRemoveArrayItem(["additionalQualifications"], index)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <QualificationForm
                qualification={qualification}
                index={index}
                onChange={(updatedQual) =>
                  handleFieldChange(
                    ["additionalQualifications", index],
                    updatedQual
                  )
                }
                onRemove={() =>
                  handleRemoveArrayItem(["additionalQualifications"], index)
                }
                previewMode={previewMode}
              />
            </Box>
          ))}
          {/*
            Uncomment if you want to allow adding from within this section:
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                handleAddArrayItem(["additionalQualifications"], {
                  qualificationType: "Certification", // Default type
                  details: {},
                })
              }
              variant="outlined"
              color="primary"
              style={{ marginTop: theme.spacing(2) }}
            >
              Add Qualification
            </Button>
          */}
        </Box>
      )}

      {/* Download Button */}
      <Box className={classes.buttonGroup}>
        <DownloadButton
          onClick={() =>
            handlePrintResume(content, "resume_template_1.docx", "resume.docx")
          }
          downloading={downloading}
          label="Download Resume"
          startIcon={<GetAppIcon />}
        />
      </Box>
    </Box>
  );
};

export default Resume;
