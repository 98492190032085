// TargetJobInformation.jsx

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
} from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Divider,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { SubsectionHeader, FormField, DateHelperText } from "./FormComponents";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";

import FormSectionSubHeader from "./FormSectionSubHeader";

import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from "../util/firebase";

import { useAuth } from "../util/auth";
import { useRouter } from "../util/router";

import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

import * as Yup from "yup";

/* MAX SETTINGS */
const MAX_JOB_ENTRIES = 10;
const MAX_TOOLKIT_SELECTIONS = 20;

/* STYLES (unchanged) */
const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  formContent: {
    position: "relative",
  },
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  entryContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  checkboxGrid: {
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(1),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  applicationDeadlineField: {
    "& .MuiInputBase-root": {},
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      color: theme.palette.primary.main,
    },
  },
  toolkitSection: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  toolkitTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  parseDialogTextArea: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  parseDialogField: {
    marginTop: theme.spacing(2),
  },
  parseDialogSection: {
    marginTop: theme.spacing(2),
  },
  parseDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

/* GENERATION OPTIONS */
const generationOptions = [
  {
    name: "resume",
    label: "Resume",
    tooltip: "Generate a tailored resume for this job",
  },
  {
    name: "coverLetter",
    label: "Cover Letter",
    tooltip: "Create a customized cover letter",
  },
  {
    name: "references",
    label: "References",
    tooltip: "Prepare a list of professional references",
  },
  {
    name: "messageToRecruiter",
    label: "Message to Recruiter",
    tooltip: "Generate a personalized message for LinkedIn recruiters",
  },
  {
    name: "linkedinProfileOptimization",
    label: "LinkedIn Profile Optimization",
    tooltip: "Get suggestions to improve your LinkedIn profile",
  },
  {
    name: "interviewPreparation",
    label: "Interview Preparation",
    tooltip: "Prepare for job interviews with tailored questions and answers",
  },
  {
    name: "networkingEmailTemplates",
    label: "Networking Email Templates",
    tooltip: "Create templates for networking emails",
  },
  {
    name: "careerDevelopmentPlan",
    label: "Career Development Plan",
    tooltip: "Get a personalized career development plan",
  },
  {
    name: "jobApplicationFollowUpEmail",
    label: "Job Application Follow-up Emails",
    tooltip: "Generate follow-up emails for your job application",
  },
  {
    name: "salaryNegotiationScript",
    label: "Salary Negotiation Scripts",
    tooltip: "Receive scripts for salary negotiations",
  },
  {
    name: "professionalBio",
    label: "Professional Bio Creation",
    tooltip: "Create a professional bio for various platforms",
  },
  {
    name: "personalBrandingTips",
    label: "Personal Branding Tips",
    tooltip: "Get tips for building your personal brand",
  },
  {
    name: "skillGapAnalysis",
    label: "Skill Gap Analysis",
    tooltip: "Identify and address skill gaps for this job",
  },
  {
    name: "companyResearchReports",
    label: "Company Research Reports",
    tooltip: "Get detailed reports on the target company",
  },
];

/* INITIAL OBJECT */
const initialTargetJobInfo = {
  jobTitle: "",
  companyName: "",
  jobLocation: "",
  jobType: "",
  salary: "",
  applicationDeadline: "",
  jobDescription: "",
  generationOptions: generationOptions.reduce((acc, option) => {
    acc[option.name] = false;
    return acc;
  }, {}),
};

/* FORM SECTIONS */
const formSections = [
  {
    title: "Job Details",
    fields: [
      {
        name: "jobTitle",
        label: "Job Title",
        required: true,
        icon: WorkIcon,
        maxLength: 100,
      },
      {
        name: "companyName",
        label: "Company Name",
        required: true,
        icon: BusinessIcon,
        maxLength: 100,
      },
      {
        name: "jobLocation",
        label: "Job Location",
        maxLength: 100,
      },
      {
        name: "jobType",
        label: "Job Type",
        required: true,
        select: true,
        options: [
          { value: "Full-time", label: "Full-time" },
          { value: "Part-time", label: "Part-time" },
          { value: "Contract", label: "Contract" },
          { value: "Internship", label: "Internship" },
          { value: "Temporary", label: "Temporary" },
        ],
      },
    ],
  },
  {
    title: "Salary and Deadline",
    fields: [
      {
        name: "salary",
        label: "Salary",
        type: "text",
      },
      {
        name: "applicationDeadline",
        label: "Application Deadline",
        type: "date",
        helperText: "Enter the deadline for submitting your application",
        InputLabelProps: { shrink: true },
      },
    ],
  },
  {
    title: "Job Description",
    fields: [
      {
        name: "jobDescription",
        label: "Job Description",
        required: true,
        multiline: true,
        rows: 10, // Set rows to 10 for a taller textarea
        fullWidth: true,
        maxLength: 8000,
      },
    ],
  },
];

/* YUP SCHEMA */
export const targetJobSchema = Yup.object().shape({
  jobTitle: Yup.string()
    .required("Job Title is required")
    .max(100, "Job Title cannot exceed 100 characters"),
  companyName: Yup.string()
    .required("Company Name is required")
    .max(100, "Company Name cannot exceed 100 characters"),
  jobLocation: Yup.string().max(
    100,
    "Job Location cannot exceed 100 characters"
  ),
  jobType: Yup.string()
    .required("Job Type is required")
    .oneOf(
      ["Full-time", "Part-time", "Contract", "Internship", "Temporary"],
      "Invalid Job Type"
    ),
  salary: Yup.string().max(100, "Salary cannot exceed 100 characters"),
  applicationDeadline: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(new Date(), "Application Deadline cannot be in the past"),
  jobDescription: Yup.string()
    .required("Job Description is required")
    .max(8000, "Job Description cannot exceed 8000 characters"),
  generationOptions: Yup.object().shape(
    generationOptions.reduce((acc, option) => {
      acc[option.name] = Yup.boolean();
      return acc;
    }, {})
  ),
});

export const formSchema = Yup.object().shape({
  targetJobInformation: Yup.array()
    .of(targetJobSchema)
    .min(1, "At least one target job must be added")
    .max(
      MAX_JOB_ENTRIES,
      `You can only add up to ${MAX_JOB_ENTRIES} target jobs`
    ),
});

/* Normalizes job data so generationOptions is never null/undefined */
const normalizeJobData = (job) => {
  const jobGenerationOptions =
    typeof job.generationOptions === "object" && job.generationOptions !== null
      ? job.generationOptions
      : {};
  return {
    ...initialTargetJobInfo,
    ...job,
    generationOptions: {
      ...initialTargetJobInfo.generationOptions,
      ...jobGenerationOptions,
    },
  };
};

/* Child component for a single Target Job entry */
const TargetJobEntry = memo(
  ({
    index,
    jobInfo,
    handleFieldChange,
    handleOptionChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
    touched,
    userCredits,
    countToolkitSelections,
    handleOpenJobParseDialog,
  }) => {
    const classes = useStyles();

    return (
      <Box className={classes.entryContainer}>
        {formSections.map((section) => (
          <React.Fragment key={section.title}>
            <Box display="flex" alignItems="center">
              <SubsectionHeader title={section.title} />
              {section.title === "Job Details" && (
                <Box ml={2}>
                  <Tooltip title="Extract from Job Description">
                    <IconButton
                      onClick={() => handleOpenJobParseDialog(index)}
                      color="primary"
                      aria-label="Parse job description"
                      style={{ marginBottom: "4px" }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Grid container spacing={3}>
              {section.fields.map((field) => (
                <Grid
                  item
                  xs={12}
                  sm={field.fullWidth ? 12 : 6}
                  key={field.name}
                >
                  <FormField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={jobInfo[field.name] ?? ""}
                    onChange={(name, value) =>
                      handleFieldChange(index, name, value)
                    }
                    error={
                      touched &&
                      touched[field.name] &&
                      errors &&
                      errors[field.name]
                    }
                    helperText={
                      touched &&
                      touched[field.name] &&
                      errors &&
                      errors[field.name]
                    }
                    tooltip={`Enter the ${field.label.toLowerCase()}`}
                    aria-label={field.label}
                    placeholder={
                      field.type === "date"
                        ? "mm/dd/yyyy"
                        : `Enter ${field.label.toLowerCase()}`
                    }
                    maxLength={field.maxLength}
                    type={field.type}
                    multiline={field.multiline}
                    rows={field.rows}
                    fullWidth
                    select={field.select}
                    options={field.options}
                    icon={field.icon ? <field.icon /> : null}
                    InputLabelProps={
                      field.type === "date" ? field.InputLabelProps : undefined
                    }
                    className={
                      field.name === "applicationDeadline"
                        ? classes.applicationDeadlineField
                        : undefined
                    }
                  >
                    {field.select &&
                      field.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </FormField>
                  {field.type === "date" && (
                    <DateHelperText text={field.helperText} />
                  )}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}

        <Paper className={classes.toolkitSection}>
          <Typography variant="h6" className={classes.toolkitTitle}>
            Job Application Toolkit
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Select the resources and documents you’d like to generate for this
            job application. Each selection will use one credit.
          </Typography>
          <Grid container spacing={2} className={classes.checkboxGrid}>
            {generationOptions.map((option) => {
              const isOptionSelected = Boolean(
                jobInfo.generationOptions[option.name]
              );
              return (
                <Grid item xs={12} sm={6} md={4} key={option.name}>
                  <Tooltip title={option.tooltip} placement="top">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOptionSelected}
                          onChange={(e) =>
                            handleOptionChange(
                              index,
                              option.name,
                              e.target.checked
                            )
                          }
                          name={option.name}
                          color="primary"
                          aria-label={option.label}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          className={classes.checkboxLabel}
                        >
                          {option.label}
                        </Typography>
                      }
                    />
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Paper>

        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this target job">
            <IconButton
              onClick={() => handleRemoveEntry(index)}
              color="secondary"
              aria-label="Remove target job"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another target job">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add target job"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

/* MAIN COMPONENT */
export const TargetJobInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [purchaseCreditsDialogOpen, setPurchaseCreditsDialogOpen] =
    useState(false);

  const auth = useAuth();
  const router = useRouter();

  const prevFormDataRef = useRef();

  // For "Parse Job" dialog
  const [parseDialogOpen, setParseDialogOpen] = useState(false);
  const [parseDialogJobIndex, setParseDialogJobIndex] = useState(null);
  const [parseDescription, setParseDescription] = useState("");
  const [parsing, setParsing] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [editParsedData, setEditParsedData] = useState({
    jobTitle: "",
    companyName: "",
    jobLocation: "",
    jobType: "",
    salary: "",
    applicationDeadline: "",
    jobDescription: "",
  });
  const [parseViewStage, setParseViewStage] = useState("input");

  /* Save data to localStorage + Firestore */
  const saveData = useCallback(
    async (data) => {
      localStorage.setItem("targetJobInformation", JSON.stringify(data));
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        try {
          await setDoc(
            userDocRef,
            { targetJobInformation: data },
            { merge: true }
          );
        } catch (error) {
          console.error("Error saving data:", error);
          throw error;
        }
      }
    },
    [auth.user]
  );

  /* Debounced auto-save */
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    [saveData]
  );

  /* On mount, load from localStorage or Firestore. Guarantee at least 1 entry. */
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("targetJobInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          if (Array.isArray(parsedData) && parsedData.length > 0) {
            const normalizedData = parsedData.map((job) =>
              normalizeJobData(job)
            );
            updateFormData("targetJobInformation", normalizedData);
          } else {
            // If empty or invalid, ensure we have at least 1
            updateFormData("targetJobInformation", [
              { ...initialTargetJobInfo },
            ]);
          }
        } else if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().targetJobInformation;
            if (Array.isArray(data) && data.length > 0) {
              const normalizedData = data.map((job) => normalizeJobData(job));
              updateFormData("targetJobInformation", normalizedData);
              localStorage.setItem(
                "targetJobInformation",
                JSON.stringify(normalizedData)
              );
            } else {
              updateFormData("targetJobInformation", [
                { ...initialTargetJobInfo },
              ]);
            }
          } else {
            updateFormData("targetJobInformation", [
              { ...initialTargetJobInfo },
            ]);
          }
        } else {
          // Not logged in => at least 1 entry
          updateFormData("targetJobInformation", [{ ...initialTargetJobInfo }]);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        // fallback => at least 1 entry
        updateFormData("targetJobInformation", [{ ...initialTargetJobInfo }]);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  /* Listen for user credits from Firestore */
  useEffect(() => {
    let unsubscribe;
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setUserCredits(data.credits || 0);
        } else {
          setUserCredits(0);
        }
      });
    } else {
      setUserCredits(0);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth.user]);

  /* Auto-save on formData changes */
  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.targetJobInformation;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.targetJobInformation, debouncedAutoSave, loading]);

  /* Count how many generationOptions are checked across all jobs */
  const countToolkitSelections = () => {
    const targetJobs = Array.isArray(formData.targetJobInformation)
      ? formData.targetJobInformation
      : [];
    return targetJobs.reduce((total, job) => {
      const genOpts = job.generationOptions || {};
      return total + Object.values(genOpts).filter(Boolean).length;
    }, 0);
  };

  /* Handle field changes (jobTitle, jobType, etc.) */
  const handleFieldChange = async (index, name, value) => {
    const updatedJob = {
      ...formData.targetJobInformation[index],
      [name]: value,
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);

    // Validate with Yup
    try {
      await targetJobSchema.validateAt(name, updatedJob);
      setErrors((prev) => {
        const jobErrors = prev[index] || {};
        delete jobErrors[name];
        return { ...prev, [index]: jobErrors };
      });
      setTouched((prev) => ({
        ...prev,
        [index]: { ...(prev[index] || {}), [name]: true },
      }));
    } catch (validationError) {
      setErrors((prev) => {
        const jobErrors = prev[index] || {};
        return {
          ...prev,
          [index]: { ...jobErrors, [name]: validationError.message },
        };
      });
      setTouched((prev) => ({
        ...prev,
        [index]: { ...(prev[index] || {}), [name]: true },
      }));
    }
  };

  /* Handle checking/unchecking generationOptions */
  const handleOptionChange = (index, optionName, checked) => {
    const totalSelections = countToolkitSelections();

    if (checked) {
      if (totalSelections >= MAX_TOOLKIT_SELECTIONS) {
        setAlertMessage(
          `You can only select up to ${MAX_TOOLKIT_SELECTIONS} toolkit options in total.`
        );
        return;
      }

      const estimatedCreditsRemaining = userCredits - (totalSelections + 1);
      if (estimatedCreditsRemaining < 0) {
        setPurchaseCreditsDialogOpen(true);
        return;
      }
    }

    const currentGenOpts =
      formData.targetJobInformation[index].generationOptions || {};
    const updatedJob = {
      ...formData.targetJobInformation[index],
      generationOptions: {
        ...currentGenOpts,
        [optionName]: checked,
      },
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);
  };

  /* Add an entry if under MAX */
  const handleAddEntry = () => {
    if (formData.targetJobInformation.length >= MAX_JOB_ENTRIES) {
      setAlertMessage(`You can only add up to ${MAX_JOB_ENTRIES} target jobs.`);
      return;
    }
    updateFormData("targetJobInformation", [
      ...formData.targetJobInformation,
      { ...initialTargetJobInfo },
    ]);
  };

  /* Remove an entry if more than 1 left */
  const handleRemoveEntry = (index) => {
    if (formData.targetJobInformation.length === 1) {
      setAlertMessage("You must have at least one target job.");
      return;
    }

    const updatedJobs = formData.targetJobInformation.filter(
      (_, i) => i !== index
    );
    updateFormData("targetJobInformation", updatedJobs);

    // Remove errors for that entry & re-index
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[index];
      const reIndexed = {};
      updatedJobs.forEach((_, i) => {
        reIndexed[i] = newErrors[i >= index ? i + 1 : i];
      });
      return reIndexed;
    });
    // Remove touched for that entry & re-index
    setTouched((prev) => {
      const newTouched = { ...prev };
      delete newTouched[index];
      const reIndexedTouched = {};
      updatedJobs.forEach((_, i) => {
        reIndexedTouched[i] = newTouched[i >= index ? i + 1 : i];
      });
      return reIndexedTouched;
    });
  };

  /* Open parse dialog for a specific job index */
  const handleOpenJobParseDialog = (jobIndex) => {
    setParseDialogJobIndex(jobIndex);
    setParseDialogOpen(true);
    setParseDescription("");
    setParsedData(null);
    setEditParsedData({
      jobTitle: "",
      companyName: "",
      jobLocation: "",
      jobType: "",
      salary: "",
      applicationDeadline: "",
      jobDescription: "",
    });
    setParseViewStage("input");
  };

  /* Close parse dialog */
  const handleCloseJobParseDialog = () => {
    setParseDialogOpen(false);
    setParseDialogJobIndex(null);
    setParsedData(null);
    setParseDescription("");
    setParseViewStage("input");
  };

  /* Call GCF to parse job description text */
  const handleParseJobDescription = async () => {
    if (!parseDescription.trim()) {
      setAlertMessage("Please enter a job description to parse.");
      return;
    }

    setParsing(true);
    setParsedData(null);
    try {
      const response = await fetch(
        "https://us-central1-resumerevivalapp.cloudfunctions.net/process_job_description",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ jobDescription: parseDescription }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to parse job description");
      }
      const data = await response.json();
      setParsedData(data);
      setEditParsedData({
        jobTitle: data.jobTitle || "",
        companyName: data.companyName || "",
        jobLocation: data.jobLocation || "",
        jobType: data.jobType || "",
        salary: data.salary || "",
        applicationDeadline: data.applicationDeadline || "",
        jobDescription: data.jobDescription || "",
      });
      setParseViewStage("review");
    } catch (error) {
      console.error(error);
      setAlertMessage("Failed to parse the job description. Please try again.");
    } finally {
      setParsing(false);
    }
  };

  /* Edit parse dialog fields */
  const handleEditParsedField = (fieldName, value) => {
    setEditParsedData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  /* Confirm parse data => merges into the job entry */
  const handleConfirmParsedData = () => {
    if (parseDialogJobIndex === null) return;
    const updatedJobs = [...formData.targetJobInformation];
    const updatedJob = { ...updatedJobs[parseDialogJobIndex] };

    updatedJob.jobTitle = editParsedData.jobTitle || updatedJob.jobTitle;
    updatedJob.companyName =
      editParsedData.companyName || updatedJob.companyName;
    updatedJob.jobLocation =
      editParsedData.jobLocation || updatedJob.jobLocation;
    updatedJob.jobType = editParsedData.jobType || updatedJob.jobType;
    updatedJob.salary = editParsedData.salary || updatedJob.salary;
    updatedJob.applicationDeadline =
      editParsedData.applicationDeadline || updatedJob.applicationDeadline;
    updatedJob.jobDescription =
      editParsedData.jobDescription || updatedJob.jobDescription;

    updatedJobs[parseDialogJobIndex] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);

    handleCloseJobParseDialog();
  };

  /* Return to "input" stage in parse dialog */
  const handleBackToInput = () => {
    setParseViewStage("input");
    setParsedData(null);
    setEditParsedData({
      jobTitle: "",
      companyName: "",
      jobLocation: "",
      jobType: "",
      salary: "",
      applicationDeadline: "",
      jobDescription: "",
    });
  };

  /* Always ensure at least 1 job is present */
  const targetJobInformation = Array.isArray(formData.targetJobInformation)
    ? formData.targetJobInformation.length > 0
      ? formData.targetJobInformation
      : [{ ...initialTargetJobInfo }]
    : [{ ...initialTargetJobInfo }];

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <FormSectionSubHeader title="Target Job Information:" />
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress aria-label="Loading target job information" />
          </Grid>
        ) : (
          <div className={classes.formContainer}>
            {targetJobInformation.map((jobInfo, index) => (
              <TargetJobEntry
                key={index}
                index={index}
                jobInfo={jobInfo}
                handleFieldChange={handleFieldChange}
                handleOptionChange={handleOptionChange}
                handleRemoveEntry={handleRemoveEntry}
                handleAddEntry={handleAddEntry}
                errors={errors[index] || {}}
                touched={touched[index] || {}}
                userCredits={userCredits}
                countToolkitSelections={countToolkitSelections}
                handleOpenJobParseDialog={handleOpenJobParseDialog}
              />
            ))}
          </div>
        )}
      </div>

      {/* Snackbar for general alerts */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert onClose={() => setAlertMessage(null)} severity="warning">
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar for auto-save status */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert}
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>

      {/* Dialog for insufficient credits */}
      <Dialog
        open={purchaseCreditsDialogOpen}
        onClose={() => setPurchaseCreditsDialogOpen(false)}
      >
        <DialogTitle>Not Enough Credits</DialogTitle>
        <DialogContent>
          <Typography>
            You don't have enough credits to select this option. Please purchase
            more credits to continue.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPurchaseCreditsDialogOpen(false)}
            color="default"
            variant="outlined"
          >
            Continue Editing
          </Button>
          <Button
            onClick={() => {
              setPurchaseCreditsDialogOpen(false);
              router.push("/pricing");
            }}
            color="primary"
            variant="contained"
          >
            Purchase Credits
          </Button>
        </DialogActions>
      </Dialog>

      {/* Parse Job Description Dialog */}
      <Dialog
        open={parseDialogOpen}
        onClose={handleCloseJobParseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Parse Job Description</DialogTitle>
        <DialogContent>
          {parseViewStage === "input" && (
            <>
              <Typography variant="body2" color="textSecondary">
                Paste the entire job description in the text area below and
                click "Parse" to extract structured information.
              </Typography>
              <TextField
                className={classes.parseDialogTextArea}
                label="Paste Job Description"
                variant="outlined"
                multiline
                minRows={20}
                maxRows={100}
                fullWidth
                value={parseDescription}
                onChange={(e) => setParseDescription(e.target.value)}
              />
              <Box marginTop={2} display="flex" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleParseJobDescription}
                  disabled={parsing}
                >
                  {parsing ? "Parsing..." : "Parse"}
                </Button>
                {parsing && (
                  <Box ml={2}>
                    <CircularProgress size={24} />
                  </Box>
                )}
              </Box>
            </>
          )}

          {parseViewStage === "review" && (
            <>
              <Box
                className={classes.parseDialogSection}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">
                  Parsed Results (Edit as Needed)
                </Typography>
                <Button color="default" onClick={handleBackToInput}>
                  Back
                </Button>
              </Box>
              <Grid container spacing={2} className={classes.parseDialogField}>
                {parsedData ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Job Title"
                        variant="outlined"
                        fullWidth
                        value={editParsedData.jobTitle}
                        onChange={(e) =>
                          handleEditParsedField("jobTitle", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        fullWidth
                        value={editParsedData.companyName}
                        onChange={(e) =>
                          handleEditParsedField("companyName", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Job Location"
                        variant="outlined"
                        fullWidth
                        value={editParsedData.jobLocation}
                        onChange={(e) =>
                          handleEditParsedField("jobLocation", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Job Type"
                        variant="outlined"
                        select
                        fullWidth
                        value={editParsedData.jobType}
                        onChange={(e) =>
                          handleEditParsedField("jobType", e.target.value)
                        }
                      >
                        <MenuItem value="">Select Job Type</MenuItem>
                        <MenuItem value="Full-time">Full-time</MenuItem>
                        <MenuItem value="Part-time">Part-time</MenuItem>
                        <MenuItem value="Contract">Contract</MenuItem>
                        <MenuItem value="Internship">Internship</MenuItem>
                        <MenuItem value="Temporary">Temporary</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Salary"
                        variant="outlined"
                        fullWidth
                        value={editParsedData.salary}
                        onChange={(e) =>
                          handleEditParsedField("salary", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Application Deadline"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={editParsedData.applicationDeadline}
                        onChange={(e) =>
                          handleEditParsedField(
                            "applicationDeadline",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Job Description"
                        variant="outlined"
                        multiline
                        rows={20}
                        fullWidth
                        value={editParsedData.jobDescription}
                        onChange={(e) =>
                          handleEditParsedField(
                            "jobDescription",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Skeleton variant="rect" height={56} />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rect" height={100} />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {parseViewStage === "input" && (
            <Button onClick={handleCloseJobParseDialog} color="default">
              Cancel
            </Button>
          )}
          {parseViewStage === "review" && parsedData && (
            <>
              <Button onClick={handleCloseJobParseDialog} color="default">
                Cancel
              </Button>
              <Button
                onClick={handleConfirmParsedData}
                color="primary"
                variant="contained"
              >
                Confirm and Inject
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TargetJobInformation;
