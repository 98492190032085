// src/components/GeneratedContentDisplay.jsx

import React, { useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types"; // Added import
import {
  Box,
  Typography,
  CssBaseline,
  MenuItem,
  ListItemIcon,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Work as WorkIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  Message as MessageIcon,
  LinkedIn as LinkedInIcon,
  School as SchoolIcon,
  Email as EmailIcon,
  AttachMoney as MoneyIcon,
  Person as PersonIcon,
  Star as StarIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
} from "@material-ui/icons";

import Resume from "./Resume";
import CoverLetter from "./CoverLetter";
import References from "./References";
import MessageToRecruiter from "./MessageToRecruiter";
import InterviewPreparation from "./InterviewPreparation";
import NetworkingEmailTemplates from "./NetworkingEmailTemplates";
import CareerDevelopmentPlan from "./CareerDevelopmentPlan";
import JobApplicationFollowUpEmail from "./JobApplicationFollowUpEmail";
import LinkedInProfileOptimization from "./LinkedInProfileOptimization";
import SalaryNegotiationScript from "./SalaryNegotiationScript";
import ProfessionalBio from "./ProfessionalBio";
import PersonalBrandingTips from "./PersonalBrandingTips";
import SkillGapAnalysis from "./SkillGapAnalysis";
import CompanyResearchReports from "./CompanyResearchReports";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";
import { FormContext } from "./FormContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    marginTop: -45,
    flexGrow: 1,
  },
  jobHeader: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    textAlign: "center",
  },
  formControl: {
    minWidth: 250,
    maxWidth: 300,
    margin: "0 auto",
  },
  selectLabel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  inputLabel: {
    color: theme.palette.text.primary,
  },
  keywordsSection: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
}));

// Helper function to safely get fields from either top-level or old content structure
function getField(generation, fieldName) {
  if (generation[fieldName]) return generation[fieldName];
  if (generation.content && generation.content[fieldName]) {
    return generation.content[fieldName];
  }
  return undefined;
}

const contentSections = {
  resume: {
    title: "Resume",
    icon: <WorkIcon />,
    component: Resume,
    getContent: (gc) => getField(gc, "resumeJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "resumeJson"),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("resumeJson", updatedContent),
      handlePrintResume: params.handlePrintResume,
      downloading: params.downloading,
      keywords: params.keywords,
    }),
  },
  coverLetter: {
    title: "Cover Letter",
    icon: <DescriptionIcon />,
    component: CoverLetter,
    getContent: (gc) => getField(gc, "coverLetterJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "coverLetterJson"),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("coverLetterJson", updatedContent),
      personalInformation: params.formData.personalInformation,
      onPersonalInfoChange: (field, value) =>
        params.updateFormData("personalInformation", {
          ...params.formData.personalInformation,
          [field]: value,
        }),
      handlePrintResume: params.handlePrintResume,
      downloading: params.downloading,
      keywords: params.keywords,
    }),
  },
  references: {
    title: "References",
    icon: <AssignmentIcon />,
    component: References,
    getContent: (gc) => getField(gc, "referencesJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "referencesJson"),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("referencesJson", updatedContent),
      keywords: params.keywords,
    }),
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: <MessageIcon />,
    component: MessageToRecruiter,
    getContent: (gc) => getField(gc, "messageToRecruiterJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "messageToRecruiterJson"),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("messageToRecruiterJson", updatedContent),
      title: "Message to Recruiter",
      keywords: params.keywords,
    }),
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: <LinkedInIcon />,
    component: LinkedInProfileOptimization,
    getContent: (gc) => getField(gc, "linkedinProfileOptimizationJson"),
    getProps: (params) => ({
      content: getField(
        params.generatedContent,
        "linkedinProfileOptimizationJson"
      ),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "linkedinProfileOptimizationJson",
          updatedContent
        ),
      keywords: params.keywords,
    }),
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: <SchoolIcon />,
    component: InterviewPreparation,
    getContent: (gc) => getField(gc, "interviewPreparationJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "interviewPreparationJson"),
      keywords: params.keywords,
    }),
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: <EmailIcon />,
    component: NetworkingEmailTemplates,
    getContent: (gc) => getField(gc, "networkingEmailTemplatesJson"),
    getProps: (params) => ({
      content: getField(
        params.generatedContent,
        "networkingEmailTemplatesJson"
      ),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "networkingEmailTemplatesJson",
          updatedContent
        ),
      keywords: params.keywords,
    }),
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: <AssignmentIcon />,
    component: CareerDevelopmentPlan,
    getContent: (gc) => getField(gc, "careerDevelopmentPlanJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "careerDevelopmentPlanJson"),
      keywords: params.keywords,
    }),
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: <EmailIcon />,
    component: JobApplicationFollowUpEmail,
    getContent: (gc) => getField(gc, "jobApplicationFollowUpEmailJson"),
    getProps: (params) => ({
      content: getField(
        params.generatedContent,
        "jobApplicationFollowUpEmailJson"
      ),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "jobApplicationFollowUpEmailJson",
          updatedContent
        ),
      keywords: params.keywords,
    }),
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: <MoneyIcon />,
    component: SalaryNegotiationScript,
    getContent: (gc) => getField(gc, "salaryNegotiationScriptJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "salaryNegotiationScriptJson"),
      keywords: params.keywords,
    }),
  },
  professionalBio: {
    title: "Professional Bio",
    icon: <PersonIcon />,
    component: ProfessionalBio,
    getContent: (gc) => getField(gc, "professionalBioJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "professionalBioJson"),
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("professionalBioJson", updatedContent),
      keywords: params.keywords,
    }),
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: <StarIcon />,
    component: PersonalBrandingTips,
    getContent: (gc) => getField(gc, "personalBrandingTipsJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "personalBrandingTipsJson"),
      keywords: params.keywords,
    }),
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: <BuildIcon />,
    component: SkillGapAnalysis,
    getContent: (gc) => getField(gc, "skillGapAnalysisJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "skillGapAnalysisJson"),
      keywords: params.keywords,
    }),
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: <BusinessIcon />,
    component: CompanyResearchReports,
    getContent: (gc) => getField(gc, "companyResearchReportsJson"),
    getProps: (params) => ({
      content: getField(params.generatedContent, "companyResearchReportsJson"),
      keywords: params.keywords,
    }),
  },
};

const GeneratedContentDisplay = ({
  generatedContent,
  updateGeneratedContent,
  jobDetails = {},
  isLoading,
  selectedApplicationMaterial,
  onSelectApplicationMaterial,
}) => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [downloading, setDownloading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const keywords = generatedContent.keywords || [];
  // console.log("In GeneratedContentDisplay - keywords:", keywords);

  const availableSections = useMemo(
    () =>
      Object.keys(contentSections).filter((key) =>
        contentSections[key].getContent(generatedContent)
      ),
    [generatedContent]
  );

  const selectedSection =
    selectedApplicationMaterial &&
    availableSections.includes(selectedApplicationMaterial)
      ? selectedApplicationMaterial
      : availableSections.length > 0
      ? availableSections[0]
      : "";

  const [currentMaterial, setCurrentMaterial] = useState(selectedSection);

  useEffect(() => {
    if (
      selectedApplicationMaterial &&
      availableSections.includes(selectedApplicationMaterial)
    ) {
      setCurrentMaterial(selectedApplicationMaterial);
    } else if (availableSections.length > 0) {
      setCurrentMaterial(availableSections[0]);
      if (onSelectApplicationMaterial) {
        onSelectApplicationMaterial(availableSections[0]);
      }
    } else {
      setCurrentMaterial("");
      if (onSelectApplicationMaterial) {
        onSelectApplicationMaterial("");
      }
    }
  }, [
    selectedApplicationMaterial,
    availableSections,
    onSelectApplicationMaterial,
  ]);

  const handleMaterialChange = (event) => {
    const newMaterial = event.target.value;
    setCurrentMaterial(newMaterial);
    if (onSelectApplicationMaterial) {
      onSelectApplicationMaterial(newMaterial);
    }
  };

  const handlePrintResume = async (
    content,
    templateName = "resume_template_10.docx",
    filename = "resume.docx",
    additionalData = {}
  ) => {
    setDownloading(true);
    try {
      const payload = {
        ...content,
        ...additionalData,
        templateName,
        outputFileName: filename,
      };

      const response = await fetch(
        "https://us-central1-resumerevivalapp.cloudfunctions.net/generate_resume_test",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate document");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setSnackbar({
        open: true,
        message: "Document downloaded successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error downloading document:", error);
      setSnackbar({
        open: true,
        message: "Error downloading document.",
        severity: "error",
      });
    } finally {
      setDownloading(false);
    }
  };

  const selectedContentSection =
    currentMaterial && contentSections[currentMaterial];
  const SelectedComponent = selectedContentSection
    ? selectedContentSection.component
    : null;
  const sectionProps = selectedContentSection
    ? selectedContentSection.getProps({
        generatedContent,
        updateGeneratedContent,
        formData,
        updateFormData,
        handlePrintResume,
        downloading,
        keywords,
      })
    : {};

  const renderContent = () => {
    if (isLoading) {
      return (
        <ApplicationMaterialSkeleton
          title={
            selectedContentSection ? selectedContentSection.title : "Loading..."
          }
        />
      );
    }

    if (!selectedContentSection) {
      return (
        <Typography color="textSecondary" align="center">
          No content available for this job.
        </Typography>
      );
    }

    const contentAvailable =
      contentSections[currentMaterial].getContent(generatedContent);
    if (!contentAvailable) {
      return (
        <Typography color="textSecondary" align="center">
          Select a section from the dropdown menu.
        </Typography>
      );
    }

    return <SelectedComponent {...sectionProps} />;
  };

  const keywordsFromGen = generatedContent.keywords || [];
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Typography variant="h5" className={classes.jobHeader}>
          {jobDetails.jobTitle && jobDetails.companyName
            ? `${jobDetails.jobTitle} at ${jobDetails.companyName}`
            : "Application Materials"}
        </Typography>

        {availableSections.length > 0 && (
          <TextField
            select
            label="Select Material"
            value={currentMaterial || ""}
            onChange={handleMaterialChange}
            variant="outlined"
            className={classes.formControl}
            SelectProps={{
              renderValue: (selected) => {
                if (selected && contentSections[selected]) {
                  return (
                    <Box className={classes.selectLabel}>
                      {contentSections[selected].icon}
                      <Typography variant="body1">
                        {contentSections[selected].title}
                      </Typography>
                    </Box>
                  );
                } else {
                  return (
                    <Typography variant="body1" color="textSecondary">
                      Select a material
                    </Typography>
                  );
                }
              },
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {availableSections.map((materialKey) => (
              <MenuItem
                key={materialKey}
                value={materialKey}
                className={classes.menuItem}
              >
                <ListItemIcon>{contentSections[materialKey].icon}</ListItemIcon>
                <Typography variant="body1">
                  {contentSections[materialKey].title}
                </Typography>
              </MenuItem>
            ))}
            {currentMaterial === "" && (
              <MenuItem value="" disabled>
                <Typography variant="body1" color="textSecondary">
                  Select a material
                </Typography>
              </MenuItem>
            )}
          </TextField>
        )}

        <Box width="100%" mt={4}>
          {renderContent()}
        </Box>

        {/* {keywordsFromGen.length > 0 ? (
          <Box className={classes.keywordsSection}>
            <Typography variant="h6" gutterBottom>
              Extracted Keywords:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {keywordsFromGen.join(", ")}
            </Typography>
          </Box>
        ) : (
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              No keywords available for this generation.
            </Typography>
          </Box>
        )} */}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </main>
    </div>
  );
};

GeneratedContentDisplay.propTypes = {
  generatedContent: PropTypes.object.isRequired,
  updateGeneratedContent: PropTypes.func.isRequired,
  jobDetails: PropTypes.shape({
    jobTitle: PropTypes.string,
    companyName: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  selectedApplicationMaterial: PropTypes.string,
  onSelectApplicationMaterial: PropTypes.func,
};

export default GeneratedContentDisplay;
