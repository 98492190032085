// src/pages/ProPage.jsx

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Meta from "../components/Meta";
import JobDetailsForm from "../components/JobDetailsForm";
import GenerationsNavBar from "../components/GenerationsNavBar";
import { requireAuth } from "../util/auth";
import { FormProvider } from "../components/FormContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Fab,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAuth } from "../util/auth";
import { db } from "../util/firebase";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  addDoc,
  getDoc,
} from "firebase/firestore";
import ATSAnalysis from "../components/ATSAnalysis";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ParseDocumentButton from "../components/ParseDocumentButton";
import SidebarToggleIcon from "../components/SidebarToggleIcon";

// Utility function to extract text content from resume JSON
function getResumeTextContent(obj) {
  if (typeof obj === "string") return obj.toLowerCase() + " ";
  if (Array.isArray(obj)) return obj.map(getResumeTextContent).join(" ");
  if (obj && typeof obj === "object") {
    return Object.values(obj).map(getResumeTextContent).join(" ");
  }
  return "";
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    marginLeft: 0,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "1440px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: "100%",
    },
  },
  sidebarButton: {
    position: "fixed",
    top: theme.spacing(3),
    left: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(3),
      left: theme.spacing(1),
    },
  },
  atsAnalysisWrapper: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: theme.spacing(10),
      maxWidth: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  scrollButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 9999,
    background: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.75rem",
  },
  fabIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: "0.9rem",
  },
  "@keyframes aurora": {
    "0%": {
      backgroundPosition: "0% 0%",
    },
    "50%": {
      backgroundPosition: "100% 0%",
    },
    "100%": {
      backgroundPosition: "0% 0%",
    },
  },
  formWrapper: {
    position: "relative",
  },
  parseButtonWrapper: {
    display: "none",
    // Position below the MUI Drawer layer so that the drawer
    // can overlay the button when open
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: "180px",
    },
  },
  mobileAuroraOverlay: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "140px",
      zIndex: 9998,
      pointerEvents: "none",
      background: "linear-gradient(to top, #3f51b5 0%, rgba(108,58,146,0) 80%)",
      backgroundSize: "200% 200%",
      animation: "$aurora 10s ease-in-out infinite",
    },
  },
  mobileParseButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "fixed",
      bottom: theme.spacing(3),
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 9999,
    },
  },
}));

const ProPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [generations, setGenerations] = useState([]);
  const [selectedGenerationId, setSelectedGenerationId] = useState(null);
  const [selectedApplicationMaterial, setSelectedApplicationMaterial] =
    useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [rememberedKeywords, setRememberedKeywords] = useState([]);

  // References for measuring the form and ATS analysis
  const formRef = useRef(null);
  const atsAnalysisRef = useRef(null);

  // For controlling the parse button positioning
  const [parseButtonLeft, setParseButtonLeft] = useState(0);
  const [isFixed, setIsFixed] = useState(false);

  // Track whether ATS analysis is in view (on mobile)
  const [atsInView, setAtsInView] = useState(false);

  /******************************************************
   *  Scroll & resize handlers in a single effect
   ******************************************************/
  useEffect(() => {
    function updateButtonPosition() {
      if (formRef.current) {
        const rect = formRef.current.getBoundingClientRect();
        setParseButtonLeft(rect.right + 10);
      }
    }

    function handleScroll() {
      // Fix/unfix parse button based on Y offset
      if (window.scrollY >= 200) setIsFixed(true);
      else setIsFixed(false);

      // For ATS Analysis: check if user has scrolled past it
      if (atsAnalysisRef.current) {
        const sectionTop = atsAnalysisRef.current.offsetTop;
        const threshold = 150;
        const scrolledBottom = window.scrollY + window.innerHeight;
        if (scrolledBottom >= sectionTop + threshold) {
          setAtsInView(true);
        } else {
          setAtsInView(false);
        }
      }
    }

    window.addEventListener("resize", updateButtonPosition);
    window.addEventListener("scroll", handleScroll);

    // Run both handlers once on mount
    updateButtonPosition();
    handleScroll();

    return () => {
      window.removeEventListener("resize", updateButtonPosition);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /******************************************************
   *  Load remembered keywords on mount
   ******************************************************/
  useEffect(() => {
    const loadRememberedKeywords = async () => {
      if (!auth.user) return;
      const userDocRef = doc(db, "users", auth.user.uid);
      const userSnap = await getDoc(userDocRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setRememberedKeywords(userData.rememberedKeywords || []);
      } else {
        setRememberedKeywords([]);
      }
    };
    loadRememberedKeywords();
  }, [auth.user]);

  /******************************************************
   *  Save new remembered keywords
   ******************************************************/
  const saveRememberedKeywords = useCallback(
    async (newKeywords) => {
      if (!auth.user) return;
      const userDocRef = doc(db, "users", auth.user.uid);
      await updateDoc(userDocRef, { rememberedKeywords: newKeywords });
    },
    [auth.user]
  );

  /******************************************************
   *  Load generations from Firestore
   ******************************************************/
  useEffect(() => {
    const loadGenerationsFromFirestore = async () => {
      if (!auth.user) return;
      const userGenerationsRef = collection(
        db,
        "users",
        auth.user.uid,
        "generations"
      );

      try {
        const querySnapshot = await getDocs(userGenerationsRef);
        const loadedGenerations = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          loadedGenerations.push({ id: docSnap.id, ...data });
        });
        setGenerations(loadedGenerations);
      } catch (error) {
        console.error("Error loading generations from Firestore:", error);
      }
    };

    loadGenerationsFromFirestore();
  }, [auth.user]);

  /******************************************************
   *  Restore a previously selected generation/application
   *  from localStorage (if any)
   ******************************************************/
  useEffect(() => {
    if (generations.length > 0) {
      const storedSelectedGenerationId = localStorage.getItem(
        "selectedGenerationId"
      );
      const storedSelectedApplicationMaterial = localStorage.getItem(
        "selectedApplicationMaterial"
      );

      if (storedSelectedGenerationId) {
        setSelectedGenerationId(storedSelectedGenerationId);
        localStorage.removeItem("selectedGenerationId");
      }
      if (storedSelectedApplicationMaterial) {
        setSelectedApplicationMaterial(storedSelectedApplicationMaterial);
        localStorage.removeItem("selectedApplicationMaterial");
      }
    }
  }, [generations]);

  /******************************************************
   *  Tab handling
   ******************************************************/
  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
  }, []);

  /******************************************************
   *  Handle creation of new generations
   ******************************************************/
  const handleAddGenerations = useCallback(
    (newGenerations) => {
      if (!newGenerations || newGenerations.length === 0) return;

      // Add new generations to local state
      setGenerations((prev) => [...prev, ...newGenerations]);

      // Select the first generation created by default
      setSelectedGenerationId(newGenerations[0].id);
      setSelectedApplicationMaterial(null);
      handleTabChange(1);

      // Save to Firestore if user is logged in
      if (auth.user) {
        const userGenerationsRef = collection(
          db,
          "users",
          auth.user.uid,
          "generations"
        );
        newGenerations.forEach(async (generation) => {
          const generationData = {
            ...generation,
            timestamp: serverTimestamp(),
          };
          try {
            if (generation.id) {
              // If there's already an ID, just merge
              const generationDocRef = doc(userGenerationsRef, generation.id);
              await setDoc(generationDocRef, generationData, { merge: true });
            } else {
              // Otherwise, create a new doc
              await addDoc(userGenerationsRef, generationData);
            }
          } catch (error) {
            console.error("Error saving generation to Firestore:", error);
          }
        });
      }
    },
    [auth.user, handleTabChange]
  );

  /******************************************************
   *  Handle updating existing generation data
   ******************************************************/
  const handleUpdateGenerationContent = useCallback(
    (generationId, updatedContent) => {
      // Update local state
      setGenerations((prev) =>
        prev.map((gen) =>
          gen.id === generationId ? { ...updatedContent } : gen
        )
      );

      // Update Firestore
      if (auth.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        setDoc(generationDocRef, updatedContent, { merge: true }).catch(
          (error) => {
            console.error("Error updating generation in Firestore:", error);
          }
        );
      }
    },
    [auth.user]
  );

  /******************************************************
   *  Handle selecting a generation
   ******************************************************/
  const handleSelectGeneration = useCallback(
    (generationId) => {
      setSelectedGenerationId(generationId);
      handleTabChange(1);

      // Close the drawer on mobile
      if (isMobile && drawerOpen) {
        setDrawerOpen(false);
      }
    },
    [drawerOpen, isMobile, handleTabChange]
  );

  /******************************************************
   *  Handle selecting which application material (resume, etc.)
   ******************************************************/
  const handleSelectApplicationMaterial = useCallback(
    (materialKey) => {
      setSelectedApplicationMaterial(materialKey);
      handleTabChange(1);

      // Close the drawer on mobile
      if (isMobile && drawerOpen) {
        setDrawerOpen(false);
      }
    },
    [drawerOpen, isMobile, handleTabChange]
  );

  /******************************************************
   *  Delete a generation from Firestore
   ******************************************************/
  const handleDeleteGeneration = useCallback(
    async (generationId) => {
      // Remove from local state
      setGenerations((prev) => prev.filter((gen) => gen.id !== generationId));

      // If currently selected generation is deleted, reset
      if (selectedGenerationId === generationId) {
        setSelectedGenerationId(null);
        setSelectedApplicationMaterial(null);
      }

      // Remove from Firestore
      if (auth.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        try {
          await deleteDoc(generationDocRef);
        } catch (error) {
          console.error("Error deleting generation from Firestore:", error);
        }
      }
    },
    [auth.user, selectedGenerationId]
  );

  /******************************************************
   *  Drawer toggle for sidebar
   ******************************************************/
  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  /******************************************************
   *  Edit a generation's metadata in Firestore
   ******************************************************/
  const handleEditGeneration = useCallback(
    async (generationId, updatedData) => {
      // Update local
      setGenerations((prev) =>
        prev.map((g) => (g.id === generationId ? { ...g, ...updatedData } : g))
      );

      // Update Firestore
      if (auth.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        try {
          await updateDoc(generationDocRef, updatedData);
        } catch (error) {
          console.error("Error updating generation in Firestore:", error);
        }
      }
    },
    [auth.user]
  );

  /******************************************************
   *  Update generation's progress in Firestore
   ******************************************************/
  const handleUpdateProgress = useCallback(
    async (generationId, step, status) => {
      setGenerations((prev) =>
        prev.map((gen) =>
          gen.id === generationId ? { ...gen, progress: step, status } : gen
        )
      );

      if (auth.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        try {
          await updateDoc(generationDocRef, { progress: step, status });
        } catch (error) {
          console.error("Error updating progress in Firestore:", error);
        }
      }
    },
    [auth.user]
  );

  /******************************************************
   *  Derived data: the currently selected generation
   ******************************************************/
  const selectedGeneration = useMemo(() => {
    return generations.find((gen) => gen.id === selectedGenerationId);
  }, [generations, selectedGenerationId]);

  /******************************************************
   *  Whether to show ATS Analysis at all
   ******************************************************/
  const showATSAnalysis =
    activeTab === 1 &&
    selectedApplicationMaterial === "resume" &&
    selectedGeneration &&
    selectedGeneration.resumeJson;

  /******************************************************
   *  Compute ATS data from resume text and known keywords
   ******************************************************/
  const {
    keywords,
    foundKeywords,
    notFoundKeywords,
    totalKeywords,
    foundCount,
    foundRatio,
    scoreLabel,
  } = useMemo(() => {
    if (!showATSAnalysis) {
      return {
        keywords: [],
        foundKeywords: [],
        notFoundKeywords: [],
        totalKeywords: 0,
        foundCount: 0,
        foundRatio: 0,
        scoreLabel: "Needs Improvement",
      };
    }

    const kw = selectedGeneration.keywords || [];
    const resumeText = getResumeTextContent(selectedGeneration.resumeJson);

    const loweredKeywords = kw.map((k) => k.toLowerCase());
    const foundLowered = loweredKeywords.filter((lkw) =>
      resumeText.includes(lkw)
    );
    const notFoundLowered = loweredKeywords.filter(
      (lkw) => !resumeText.includes(lkw)
    );

    const foundOriginal = kw.filter((keyword) =>
      foundLowered.includes(keyword.toLowerCase())
    );
    const notFoundOriginal = kw.filter((keyword) =>
      notFoundLowered.includes(keyword.toLowerCase())
    );

    const total = kw.length;
    const foundCt = foundOriginal.length;
    const ratio = total > 0 ? (foundCt / total) * 100 : 0;

    let label = "Needs Improvement";
    if (ratio >= 80) label = "Excellent";
    else if (ratio >= 50) label = "Good";

    return {
      keywords: kw,
      foundKeywords: foundOriginal,
      notFoundKeywords: notFoundOriginal,
      totalKeywords: total,
      foundCount: foundCt,
      foundRatio: ratio,
      scoreLabel: label,
    };
  }, [showATSAnalysis, selectedGeneration]);

  /******************************************************
   *  Add missing keywords to the resume's skills array
   ******************************************************/
  const onAddKeywordsToSkills = useCallback(
    (newKeywords) => {
      if (!selectedGeneration || !selectedGenerationId) return;
      const resumeJson = selectedGeneration.resumeJson || {};
      const currentSkills = resumeJson.skills || [];
      const updatedSkills = Array.from(
        new Set([...newKeywords, ...currentSkills])
      );
      const updatedContent = {
        ...selectedGeneration,
        resumeJson: {
          ...resumeJson,
          skills: updatedSkills,
        },
      };
      handleUpdateGenerationContent(selectedGenerationId, updatedContent);
    },
    [selectedGeneration, selectedGenerationId, handleUpdateGenerationContent]
  );

  /******************************************************
   *  Remembered keywords manipulation
   ******************************************************/
  const handleAddRememberedKeyword = useCallback(
    async (keyword) => {
      const newKeywords = Array.from(new Set([...rememberedKeywords, keyword]));
      setRememberedKeywords(newKeywords);
      await saveRememberedKeywords(newKeywords);
    },
    [rememberedKeywords, saveRememberedKeywords]
  );

  const handleAddMultipleRememberedKeywords = useCallback(
    async (keywordsToAdd) => {
      const newSet = new Set(rememberedKeywords.map((k) => k.toLowerCase()));
      for (const kw of keywordsToAdd) {
        newSet.add(kw.toLowerCase());
      }
      // Re-map to original casing if possible
      const finalKeywords = Array.from(newSet).map((k) => {
        const original =
          rememberedKeywords.find((rw) => rw.toLowerCase() === k) ||
          keywordsToAdd.find((nw) => nw.toLowerCase() === k) ||
          k;
        return original;
      });
      setRememberedKeywords(finalKeywords);
      await saveRememberedKeywords(finalKeywords);
    },
    [rememberedKeywords, saveRememberedKeywords]
  );

  const handleRemoveRememberedKeyword = useCallback(
    async (keyword) => {
      const newKeywords = rememberedKeywords.filter((k) => k !== keyword);
      setRememberedKeywords(newKeywords);
      await saveRememberedKeywords(newKeywords);
    },
    [rememberedKeywords, saveRememberedKeywords]
  );

  /******************************************************
   *  Filter remembered keywords that are not found in resume
   ******************************************************/
  const rememberedNotFoundKeywords = useMemo(() => {
    const rememberedLower = rememberedKeywords.map((k) => k.toLowerCase());
    return notFoundKeywords.filter((keyword) =>
      rememberedLower.includes(keyword.toLowerCase())
    );
  }, [rememberedKeywords, notFoundKeywords]);

  /******************************************************
   *  ATS Analysis data to pass down
   ******************************************************/
  const atsKeywordsData = {
    keywords,
    foundKeywords,
    notFoundKeywords,
    rememberedKeywords,
    rememberedNotFoundKeywords,
    totalKeywords,
    foundCount,
    foundRatio,
    scoreLabel,
    onAddKeywordsToSkills,
    onAddRememberedKeyword: handleAddRememberedKeyword,
    onRemoveRememberedKeyword: handleRemoveRememberedKeyword,
    onAddMultipleRememberedKeywords: handleAddMultipleRememberedKeywords,
  };

  /******************************************************
   *  Scrolling to the ATS section (mobile-friendly)
   ******************************************************/
  const scrollToATSAnalysis = () => {
    if (atsAnalysisRef.current) {
      const offset = atsAnalysisRef.current.offsetTop - 80;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  return (
    <>
      <Meta title="Pro" />
      <FormProvider>
        <div className={classes.root}>
          <Tooltip
            title={
              drawerOpen
                ? "Close Generations Sidebar"
                : "View Past Application Materials"
            }
            arrow
          >
            <IconButton
              color="inherit"
              aria-label={
                drawerOpen
                  ? "Close Generations Sidebar"
                  : "View Past Application Materials"
              }
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.sidebarButton}
            >
              <SidebarToggleIcon />
            </IconButton>
          </Tooltip>

          {/* Generations Sidebar */}
          <GenerationsNavBar
            generations={generations}
            onSelectGeneration={handleSelectGeneration}
            selectedGenerationId={selectedGenerationId}
            drawerOpen={drawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            onDeleteGeneration={handleDeleteGeneration}
            selectedApplicationMaterial={selectedApplicationMaterial}
            onSelectApplicationMaterial={handleSelectApplicationMaterial}
            onEditGeneration={handleEditGeneration}
            onUpdateProgress={handleUpdateProgress}
          />

          {/* Main Content */}
          <main className={classes.content}>
            <Container className={classes.container} maxWidth="xl">
              {showATSAnalysis ? (
                <Grid container spacing={4} alignItems="flex-start">
                  {/* LEFT COLUMN: Form + ATS (on mobile) */}
                  <Grid
                    item
                    xs={12}
                    md={8}
                    ref={formRef}
                    className={classes.formWrapper}
                  >
                    <JobDetailsForm
                      generations={generations}
                      onAddGenerations={handleAddGenerations}
                      onUpdateGenerationContent={handleUpdateGenerationContent}
                      onContentGenerated={() => {}}
                      selectedGeneration={selectedGeneration}
                      selectedGenerationId={selectedGenerationId}
                      onSelectGeneration={handleSelectGeneration}
                      handleDrawerToggle={handleDrawerToggle}
                      selectedApplicationMaterial={selectedApplicationMaterial}
                      onSelectApplicationMaterial={
                        handleSelectApplicationMaterial
                      }
                      activeTab={activeTab}
                      setActiveTab={handleTabChange}
                    />

                    {/* ATS Analysis on mobile */}
                    {isMobile && (
                      <Box
                        className={classes.atsAnalysisWrapper}
                        mt={4}
                        ref={atsAnalysisRef}
                      >
                        <ATSAnalysis {...atsKeywordsData} />
                      </Box>
                    )}
                  </Grid>

                  {/* RIGHT COLUMN: ATS analysis (desktop) */}
                  <Grid item xs={12} md={4}>
                    {!isMobile && (
                      <Box
                        className={classes.atsAnalysisWrapper}
                        mt={4}
                        ref={atsAnalysisRef}
                      >
                        <ATSAnalysis {...atsKeywordsData} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={4} alignItems="flex-start">
                  {/* LEFT COLUMN: Form */}
                  <Grid
                    item
                    xs={12}
                    md={8}
                    ref={formRef}
                    className={classes.formWrapper}
                  >
                    <JobDetailsForm
                      generations={generations}
                      onAddGenerations={handleAddGenerations}
                      onUpdateGenerationContent={handleUpdateGenerationContent}
                      onContentGenerated={() => {}}
                      selectedGeneration={selectedGeneration}
                      selectedGenerationId={selectedGenerationId}
                      onSelectGeneration={handleSelectGeneration}
                      handleDrawerToggle={handleDrawerToggle}
                      selectedApplicationMaterial={selectedApplicationMaterial}
                      onSelectApplicationMaterial={
                        handleSelectApplicationMaterial
                      }
                      activeTab={activeTab}
                      setActiveTab={handleTabChange}
                    />
                  </Grid>

                  {/* RIGHT COLUMN (placeholder for future content) */}
                  <Grid item xs={12} md={4} />
                </Grid>
              )}
            </Container>
          </main>
        </div>

        {/* DESKTOP parse button: dynamic position (fixed vs absolute) */}
        {activeTab === 0 && (
          <Box
            className={classes.parseButtonWrapper}
            style={{
              position: isFixed ? "fixed" : "absolute",
              top: isFixed ? 150 : 348,
              left: parseButtonLeft,
            }}
          >
            <ParseDocumentButton />
          </Box>
        )}

        {/* Aurora gradient overlay at the bottom on mobile ONLY */}
        {activeTab === 0 && <div className={classes.mobileAuroraOverlay} />}

        {/* Mobile parse button (floating at bottom-center) */}
        {activeTab === 0 && (
          <div className={classes.mobileParseButton}>
            <ParseDocumentButton />
          </div>
        )}

        {showATSAnalysis && isMobile && !drawerOpen && !atsInView && (
          <Tooltip title="View ATS Analysis" arrow>
            <Fab
              variant="extended"
              color="primary"
              className={classes.scrollButton}
              onClick={scrollToATSAnalysis}
              aria-label="View ATS Analysis"
            >
              <ArrowDownwardIcon className={classes.fabIcon} />
              ATS Analysis
            </Fab>
          </Tooltip>
        )}
      </FormProvider>
    </>
  );
};

export default requireAuth(ProPage);
