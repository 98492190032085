// src/components/GenerationsNavBar.jsx

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import {
  List,
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  Drawer,
  IconButton,
  Divider,
  Hidden,
  TextField,
  InputAdornment,
  ListItemIcon,
  Avatar,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Collapse,
  Checkbox,
  ListItemSecondaryAction,
  Grid,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Paper,
  Snackbar,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import {
  ExpandLess,
  ExpandMore,
  Star,
  StarBorder,
  FilterList,
  Clear as ClearIcon,
  HelpOutline,
  GetApp as GetAppIcon,
} from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MuiAlert from "@material-ui/lab/Alert";

import WorkIcon from "@material-ui/icons/Work";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MessageIcon from "@material-ui/icons/Message";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import SchoolIcon from "@material-ui/icons/School";
import EmailIcon from "@material-ui/icons/Email";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import StarIconMaterial from "@material-ui/icons/Star";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIconMaterial from "@material-ui/icons/Business";

import GenerationsCalendar from "./GenerationsCalendar";

const drawerWidth = 320;
const NAVBAR_HEIGHT = 100;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    top: NAVBAR_HEIGHT,
    position: "fixed",
    height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  header: {
    padding: theme.spacing(2),
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1),
  },
  searchField: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    minWidth: 150,
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    marginLeft: theme.spacing(1),
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  footer: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  jobDetailItem: {
    marginBottom: theme.spacing(1),
  },
  filterToggle: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    userSelect: "none",
  },
  filtersContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: lighten(theme.palette.background.paper, 0.03),
    borderRadius: theme.shape.borderRadius,
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  progressChip: {
    marginLeft: theme.spacing(1),
  },
  stepper: {
    padding: theme.spacing(2, 0),
  },
  radioGroupCentered: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  infoDialogContent: {
    "& > div": {
      marginBottom: theme.spacing(1.5),
    },
  },
  listContainer: {
    flexGrow: 1,
    position: "relative",
  },
  dialogTitleRoot: {
    paddingRight: theme.spacing(5),
  },
  toolbarTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
}));

const applicationMaterials = {
  resume: {
    title: "Resume",
    icon: WorkIcon,
  },
  coverLetter: {
    title: "Cover Letter",
    icon: DescriptionIcon,
  },
  references: {
    title: "References",
    icon: AssignmentIcon,
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: MessageIcon,
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: LinkedInIcon,
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: SchoolIcon,
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: EmailIcon,
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: AssignmentIcon,
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: EmailIcon,
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: MoneyIcon,
  },
  professionalBio: {
    title: "Professional Bio",
    icon: PersonIcon,
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: StarIconMaterial,
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: BuildIcon,
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: BusinessIconMaterial,
  },
};

const progressLabels = ["Applied", "Interview", "Offer", "Accepted"];

function getProgressChipStyle(progress, status) {
  let backgroundColor;
  let color = "#fff";
  if (status === "closed") {
    backgroundColor = "#f44336"; // red
  } else {
    switch (progress) {
      case 0:
        backgroundColor = "#3f51b5"; // blue
        break;
      case 1:
        backgroundColor = "#ff9800"; // orange
        break;
      case 2:
        backgroundColor = "#4caf50"; // green
        break;
      case 3:
        backgroundColor = "#2e7d32"; // dark green
        break;
      default:
        backgroundColor = "#3f51b5";
        break;
    }
  }
  return { backgroundColor, color };
}

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

function SortIconPlaceholder() {
  return (
    <Box
      width={24}
      height={24}
      borderRadius="50%"
      border="1px solid currentColor"
      display="inline-block"
      mr={1}
    />
  );
}

function constructDomain(companyName) {
  const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, "");
  return `${sanitized}.com`;
}

function getLogoUrl(companyName) {
  const domain = constructDomain(companyName);
  return `https://logo.clearbit.com/${domain}`;
}

function parseTimestamp(timestamp) {
  if (timestamp instanceof Date) {
    return timestamp;
  } else if (timestamp && typeof timestamp.toDate === "function") {
    return timestamp.toDate();
  } else if (typeof timestamp === "string") {
    let str = timestamp.replace(" at ", " ");
    str = str.replace(/\u202F/g, " ");
    str = str.replace(/UTC[\+\-]\d+/, "");
    return new Date(str);
  } else {
    return new Date();
  }
}

function parseSalary(salaryStr) {
  if (!salaryStr || typeof salaryStr !== "string") {
    return null;
  }

  try {
    salaryStr = salaryStr.replace(/[^\d\-–,]/g, "").trim();
    if (salaryStr.includes("-") || salaryStr.includes("–")) {
      const delimiter = salaryStr.includes("-") ? "-" : "–";
      const [minStr, maxStr] = salaryStr.split(delimiter);
      const min = parseInt(minStr.replace(/,/g, ""));
      const max = parseInt(maxStr.replace(/,/g, ""));
      if (!isNaN(min) && !isNaN(max)) {
        return (min + max) / 2;
      }
    }
    const salary = parseInt(salaryStr.replace(/,/g, ""));
    if (!isNaN(salary)) {
      return salary;
    }
    return null;
  } catch (error) {
    return null;
  }
}

// Helper to check if a given generation has a certain material key
function hasMaterial(generation, key) {
  // Check top-level field first
  if (generation[key + "Json"]) return true;
  // Check content field
  if (generation.content && generation.content[key + "Json"]) return true;
  return false;
}

const GenerationsNavBar = ({
  generations,
  onSelectGeneration,
  selectedGenerationId,
  drawerOpen,
  handleDrawerToggle,
  onDeleteGeneration,
  selectedApplicationMaterial,
  onSelectApplicationMaterial,
  onEditGeneration,
  onUpdateProgress,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGenerations, setExpandedGenerations] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeletionMode, setIsDeletionMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [sortOption, setSortOption] = useState("dateDesc");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [filterActive, setFilterActive] = useState(true);
  const [filterClosed, setFilterClosed] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [filterInterview, setFilterInterview] = useState(true);
  const [filterOffer, setFilterOffer] = useState(true);
  const [filterAccepted, setFilterAccepted] = useState(true);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const debouncedSearchRef = useRef(null);

  useEffect(() => {
    if (debouncedSearchRef.current) {
      clearTimeout(debouncedSearchRef.current);
    }
    debouncedSearchRef.current = setTimeout(() => {
      // Debounce completed
    }, 300);
    return () => {
      if (debouncedSearchRef.current) {
        clearTimeout(debouncedSearchRef.current);
      }
    };
  }, [searchQuery]);

  const toggleFavorite = useCallback((id) => {
    setFavorites((prev) =>
      prev.includes(id) ? prev.filter((fid) => fid !== id) : [...prev, id]
    );
  }, []);

  const matchesStatusFilters = useCallback(
    (generation) => {
      const { progress = 0, status = "active" } = generation;
      if (status === "closed" && !filterClosed) return false;
      if (status !== "closed" && !filterActive) return false;
      if (progress === 0 && !filterApplied) return false;
      if (progress === 1 && !filterInterview) return false;
      if (progress === 2 && !filterOffer) return false;
      if (progress === 3 && !filterAccepted) return false;
      return true;
    },
    [
      filterActive,
      filterClosed,
      filterApplied,
      filterInterview,
      filterOffer,
      filterAccepted,
    ]
  );

  const filteredGenerations = useMemo(() => {
    let filtered = generations;

    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      filtered = filtered.filter((g) => {
        const companyMatch = g.companyName.toLowerCase().includes(q);
        const jobTitleMatch = g.jobTitle.toLowerCase().includes(q);
        const jobDescMatch =
          g.jobDetails.jobDescription &&
          g.jobDetails.jobDescription.toLowerCase().includes(q);
        return companyMatch || jobTitleMatch || jobDescMatch;
      });
    }

    filtered = filtered.filter((g) => matchesStatusFilters(g));

    if (showOnlyFavorites) {
      filtered = filtered.filter((g) => favorites.includes(g.id));
    }

    filtered.sort((a, b) => {
      const aFav = favorites.includes(a.id);
      const bFav = favorites.includes(b.id);
      if (aFav && !bFav) return -1;
      if (!aFav && bFav) return 1;
      return 0;
    });

    filtered.sort((a, b) => {
      if (sortOption === "salaryAsc" || sortOption === "salaryDesc") {
        const salaryA = parseSalary(
          a.jobDetails && a.jobDetails.salary ? a.jobDetails.salary : ""
        );
        const salaryB = parseSalary(
          b.jobDetails && b.jobDetails.salary ? b.jobDetails.salary : ""
        );
        if (salaryA === null && salaryB === null) return 0;
        if (salaryA === null) return 1;
        if (salaryB === null) return -1;
        return sortOption === "salaryAsc"
          ? salaryA - salaryB
          : salaryB - salaryA;
      } else {
        const dateA = parseTimestamp(a.timestamp);
        const dateB = parseTimestamp(b.timestamp);
        return sortOption === "dateAsc" ? dateA - dateB : dateB - dateA;
      }
    });

    return filtered;
  }, [
    generations,
    searchQuery,
    sortOption,
    favorites,
    showOnlyFavorites,
    matchesStatusFilters,
  ]);

  const handleGenerationClick = (generationId) => {
    const currentIndex = expandedGenerations.indexOf(generationId);
    const newExpandedGenerations = [...expandedGenerations];

    if (currentIndex === -1) {
      newExpandedGenerations.push(generationId);
    } else {
      newExpandedGenerations.splice(currentIndex, 1);
    }

    setExpandedGenerations(newExpandedGenerations);
  };

  const handleToggleSelection = (id) => {
    const currentIndex = selectedForDeletion.indexOf(id);
    const newSelected = [...selectedForDeletion];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedForDeletion(newSelected);
  };

  const clearAllFilters = () => {
    setSearchQuery("");
    setSortOption("dateDesc");
    setFilterActive(true);
    setFilterClosed(true);
    setFilterApplied(true);
    setFilterInterview(true);
    setFilterOffer(true);
    setFilterAccepted(true);
    setShowOnlyFavorites(false);
  };

  const exportToCSV = () => {
    const fields = [
      "companyName",
      "jobTitle",
      "status",
      "progress",
      "timestamp",
    ];
    const rows = filteredGenerations.map((gen) => {
      return fields.map((field) => {
        if (field === "timestamp") {
          return parseTimestamp(gen[field]).toISOString();
        }
        return gen[field] !== undefined ? gen[field] : "";
      });
    });
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [fields.join(","), ...rows.map((r) => r.join(","))].join("\n");
    const encodedUri = encodeURI(csvContent);
    const a = document.createElement("a");
    a.setAttribute("href", encodedUri);
    a.setAttribute("download", "generations.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setSnackbar({
      open: true,
      message: "Exported to CSV successfully!",
      severity: "success",
    });
  };

  const drawerContent = (
    <>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.toolbarTitle}>
          Your Generated Applications
        </Typography>
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchField}
            variant="outlined"
            placeholder="Search (Company, Title, Description)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              "aria-label": "Search Generations",
            }}
          />
          {searchQuery && (
            <Tooltip title="Clear Search">
              <IconButton
                onClick={() => setSearchQuery("")}
                aria-label="Clear search"
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          )}
          {isMobile && (
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeButton}
              aria-label="Close drawer"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <InputLabel id="sort-by-label">Sort By</InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            label="Sort By"
          >
            <MenuItem value="dateDesc">Date (Newest)</MenuItem>
            <MenuItem value="dateAsc">Date (Oldest)</MenuItem>
            <MenuItem value="salaryDesc">Salary (High to Low)</MenuItem>
            <MenuItem value="salaryAsc">Salary (Low to High)</MenuItem>
          </Select>
        </FormControl>

        <Box
          className={classes.filterToggle}
          onClick={() => setFiltersOpen(!filtersOpen)}
          aria-label="Toggle filters"
        >
          <FilterList style={{ marginRight: 8 }} />
          <Typography variant="body2" color="textSecondary">
            {filtersOpen ? "Hide Filters" : "Show Filters"}
          </Typography>
          <Box flexGrow={1} />
          {filtersOpen ? <ExpandLess /> : <ExpandMore />}
        </Box>

        <Collapse in={filtersOpen}>
          <Paper className={classes.filtersContainer} elevation={0}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" gutterBottom>
                Status & Progress Filters
              </Typography>
              <Tooltip title="Filter by active/closed status and progress stages. You can also show only favorites.">
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterActive}
                    onChange={(e) => setFilterActive(e.target.checked)}
                    color="primary"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterClosed}
                    onChange={(e) => setFilterClosed(e.target.checked)}
                    color="primary"
                  />
                }
                label="Not Selected"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterApplied}
                    onChange={(e) => setFilterApplied(e.target.checked)}
                    color="primary"
                  />
                }
                label="Applied"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterInterview}
                    onChange={(e) => setFilterInterview(e.target.checked)}
                    color="primary"
                  />
                }
                label="Interview"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterOffer}
                    onChange={(e) => setFilterOffer(e.target.checked)}
                    color="primary"
                  />
                }
                label="Offer"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterAccepted}
                    onChange={(e) => setFilterAccepted(e.target.checked)}
                    color="primary"
                  />
                }
                label="Accepted"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnlyFavorites}
                    onChange={(e) => setShowOnlyFavorites(e.target.checked)}
                    color="primary"
                  />
                }
                label="Show Only Favorites"
              />
            </FormGroup>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button
                startIcon={<ClearIcon />}
                onClick={clearAllFilters}
                size="small"
              >
                Clear Filters
              </Button>
            </Box>
          </Paper>
        </Collapse>
      </div>
      <Divider />
      <div className={classes.content}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className={classes.listContainer}
        >
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="textSecondary">
              Results: {filteredGenerations.length}
            </Typography>
            {filteredGenerations.length > 0 && (
              <Tooltip title="Export to CSV">
                <Button
                  size="small"
                  onClick={exportToCSV}
                  startIcon={<GetAppIcon />}
                >
                  Export CSV
                </Button>
              </Tooltip>
            )}
          </Box>
          <List>
            {filteredGenerations.map((generation) => (
              <GenerationListItem
                key={generation.id}
                generation={generation}
                isExpanded={expandedGenerations.includes(generation.id)}
                isSelected={selectedGenerationId === generation.id}
                isDeletionMode={isDeletionMode}
                selectedForDeletion={selectedForDeletion}
                handleGenerationClick={handleGenerationClick}
                handleToggleSelection={handleToggleSelection}
                onSelectGeneration={onSelectGeneration}
                onSelectApplicationMaterial={onSelectApplicationMaterial}
                selectedApplicationMaterial={selectedApplicationMaterial}
                classes={classes}
                handleDrawerToggle={handleDrawerToggle}
                isFavorite={favorites.includes(generation.id)}
                onToggleFavorite={toggleFavorite}
                onUpdateProgress={onUpdateProgress}
                setSnackbar={setSnackbar}
              />
            ))}

            {filteredGenerations.length === 0 && (
              <Box p={2}>
                <Typography variant="body1" color="textSecondary">
                  No job generations match your search or filters.
                </Typography>
              </Box>
            )}
          </List>
        </PerfectScrollbar>
      </div>

      <div className={classes.footer}>
        {isDeletionMode ? (
          <>
            <Button
              onClick={() => {
                setIsDeletionMode(false);
                setSelectedForDeletion([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedForDeletion.length > 0) {
                  setDeleteDialogOpen(true);
                }
              }}
              color="secondary"
            >
              Delete
            </Button>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            <Tooltip title="Open Calendar" aria-label="Open Calendar">
              <IconButton
                onClick={() => setCalendarOpen(true)}
                aria-label="Open calendar"
              >
                <CalendarTodayIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Multiple" aria-label="Delete Multiple">
              <IconButton
                onClick={() => {
                  setIsDeletionMode(true);
                  setSelectedForDeletion([]);
                }}
                className={classes.deleteButton}
                aria-label="Delete generations"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="How to Use" aria-label="How to Use">
              <IconButton
                onClick={() => setInfoDialogOpen(true)}
                aria-label="Open info dialog"
              >
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </div>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        generations={generations}
        selectedForDeletion={selectedForDeletion}
        onDeleteGeneration={onDeleteGeneration}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setIsDeletionMode={setIsDeletionMode}
        setSelectedForDeletion={setSelectedForDeletion}
      />

      <Dialog
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="generations-calendar-dialog"
      >
        <DialogTitle
          id="generations-calendar-dialog"
          classes={{ root: classes.dialogTitleRoot }}
        >
          Application Materials Calendar
          <IconButton
            aria-label="close"
            onClick={() => setCalendarOpen(false)}
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {generations.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              You have no generated content to display on the calendar.
            </Typography>
          ) : (
            <GenerationsCalendar
              generations={generations}
              onSelectGeneration={(generationId, applicationMaterialKey) => {
                onSelectGeneration(generationId);
                if (applicationMaterialKey) {
                  onSelectApplicationMaterial(applicationMaterialKey);
                }
                setCalendarOpen(false);
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              onEditGeneration={(id, data) => {
                onEditGeneration(id, data);
                setSnackbar({
                  open: true,
                  message: "Event updated successfully!",
                  severity: "success",
                });
              }}
              onDeleteGeneration={(id) => {
                onDeleteGeneration(id);
                setSnackbar({
                  open: true,
                  message: "Generation deleted successfully!",
                  severity: "success",
                });
              }}
              onUpdateProgress={(id, step, status) => {
                onUpdateProgress(id, step, status);
                setSnackbar({
                  open: true,
                  message: "Progress updated successfully!",
                  severity: "success",
                });
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        aria-labelledby="info-dialog-title"
      >
        <DialogTitle id="info-dialog-title">
          How to Use
          <IconButton
            aria-label="close"
            onClick={() => setInfoDialogOpen(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.infoDialogContent} dividers>
          <Typography variant="body1" gutterBottom>
            This panel helps you manage all your generated job applications more
            effectively.
          </Typography>
          <Box display="flex" alignItems="center">
            <SearchIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Search:</strong> Find applications by company name, job
              title, or description.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <FilterList style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Filters:</strong> Adjust visibility by status, progress,
              or favorites.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <SortIconPlaceholder />
            <Typography variant="body2">
              <strong>Sort:</strong> Order results by date or salary.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <StarBorder style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Favorites:</strong> Mark important applications with a
              star.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <DeleteIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Delete:</strong> Enter deletion mode to remove multiple
              items at once.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Calendar:</strong> Visualize applications on a calendar
              and manage their schedule or progress.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <GetAppIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Export:</strong> Export your filtered list as a CSV file.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <HelpOutline style={{ marginRight: 8 }} />
            <Typography variant="body2">
              <strong>Update Progress:</strong> Click to view job details and
              then adjust progress or mark as Not Selected.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setInfoDialogOpen(false)}
            color="primary"
            autoFocus
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar((prev) => ({
            ...prev,
            open: false,
          }))
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() =>
            setSnackbar((prev) => ({
              ...prev,
              open: false,
            }))
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <nav aria-label="generated applications">
      <Hidden smUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawerContent}
        </SwipeableDrawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  );
};

function GenerationListItem({
  generation,
  isExpanded,
  isSelected,
  isDeletionMode,
  selectedForDeletion,
  handleGenerationClick,
  handleToggleSelection,
  onSelectGeneration,
  onSelectApplicationMaterial,
  selectedApplicationMaterial,
  classes,
  handleDrawerToggle,
  isFavorite,
  onToggleFavorite,
  onUpdateProgress,
  setSnackbar,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [jobDetailsOpen, setJobDetailsOpen] = useState(false);

  // Check which materials are available with fallback
  const materialsAvailable = useMemo(
    () =>
      Object.keys(applicationMaterials).filter((key) =>
        hasMaterial(generation, key)
      ),
    [generation]
  );

  const logoUrl = getLogoUrl(generation.companyName);

  const salary =
    generation.jobDetails && generation.jobDetails.salary
      ? generation.jobDetails.salary
      : "";

  const progress = generation.progress || 0;
  const status = generation.status || "active";
  const progressLabel = progressLabels[progress] || "Applied";
  const statusLabel = status === "closed" ? "Not Selected" : "Active";
  const progressChipStyle = getProgressChipStyle(progress, status);

  return (
    <>
      <ListItem
        button
        onClick={() => {
          if (isDeletionMode) {
            handleToggleSelection(generation.id);
          } else {
            setJobDetailsOpen(true);
          }
        }}
        className={classes.listItem}
        selected={isSelected}
      >
        {isDeletionMode && (
          <Checkbox
            edge="start"
            checked={selectedForDeletion.includes(generation.id)}
            tabIndex={-1}
            disableRipple
            onClick={(e) => e.stopPropagation()}
            onChange={() => handleToggleSelection(generation.id)}
          />
        )}
        <ListItemIcon>
          <Avatar
            src={logoUrl}
            alt={`${generation.companyName} Logo`}
            className={classes.avatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "";
            }}
          >
            <BusinessIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {generation.companyName}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" color="textPrimary">
                {generation.jobTitle}
              </Typography>
              {salary && salary !== "" && (
                <Typography variant="body2" color="textSecondary">
                  {`Salary: ${salary}`}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {`Generated on: ${parseTimestamp(
                  generation.timestamp
                ).toLocaleDateString()}`}
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Tooltip title={`Status: ${statusLabel}`}>
                  <Chip
                    label={statusLabel}
                    size="small"
                    style={{
                      backgroundColor:
                        status === "closed" ? "#f44336" : "#607d8b",
                      color: "#fff",
                      marginRight: theme.spacing(1),
                    }}
                  />
                </Tooltip>
                <Tooltip title={`Progress: ${progressLabel}`}>
                  <Chip
                    label={progressLabel}
                    size="small"
                    className={classes.progressChip}
                    style={progressChipStyle}
                  />
                </Tooltip>
              </Box>
            </>
          }
          disableTypography
        />
        <ListItemSecondaryAction>
          {!isDeletionMode && (
            <>
              <Tooltip title={isFavorite ? "Unfavorite" : "Favorite"}>
                <IconButton
                  edge="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleFavorite(generation.id);
                  }}
                  aria-label="Toggle favorite"
                >
                  {isFavorite ? (
                    <Star style={{ color: "#FFD700" }} />
                  ) : (
                    <StarBorder />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
                <IconButton
                  edge="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGenerationClick(generation.id);
                  }}
                  aria-label="expand"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isExpanded && !isDeletionMode} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {materialsAvailable.length > 0 ? (
            materialsAvailable.map((key) => {
              const MaterialIcon = applicationMaterials[key].icon;
              return (
                <ListItem
                  button
                  key={key}
                  className={classes.nestedListItem}
                  selected={isSelected && selectedApplicationMaterial === key}
                  onClick={() => {
                    onSelectGeneration(generation.id);
                    onSelectApplicationMaterial(key);
                    if (isMobile) {
                      handleDrawerToggle();
                    }
                  }}
                >
                  <ListItemIcon>
                    <MaterialIcon />
                  </ListItemIcon>
                  <ListItemText primary={applicationMaterials[key].title} />
                </ListItem>
              );
            })
          ) : (
            <ListItem className={classes.nestedListItem}>
              <ListItemText primary="No application materials available." />
            </ListItem>
          )}
        </List>
      </Collapse>
      <JobDetailsDialog
        open={jobDetailsOpen}
        onClose={() => setJobDetailsOpen(false)}
        jobDetails={generation.jobDetails}
        salary={salary}
        companyLogo={logoUrl}
        companyName={generation.companyName}
        jobTitle={generation.jobTitle}
        timestamp={generation.timestamp}
        classes={classes}
        progress={generation.progress || 0}
        status={generation.status || "active"}
        onUpdateProgress={onUpdateProgress}
        generationId={generation.id}
        setSnackbar={setSnackbar}
      />
    </>
  );
}

function JobDetailsDialog({
  open,
  onClose,
  jobDetails,
  salary,
  companyLogo,
  companyName,
  jobTitle,
  timestamp,
  classes,
  progress,
  status,
  onUpdateProgress,
  generationId,
  setSnackbar,
}) {
  const theme = useTheme();
  const steps = ["Applied", "Interview", "Offer", "Accepted"];
  const [activeStep, setActiveStep] = useState(progress);
  const [jobStatus, setJobStatus] = useState(status);

  const handleStepChange = useCallback(
    async (step) => {
      setActiveStep(step);
      await onUpdateProgress(generationId, step, jobStatus);
      setSnackbar({
        open: true,
        message: "Progress updated successfully!",
        severity: "success",
      });
    },
    [onUpdateProgress, generationId, jobStatus, setSnackbar]
  );

  const handleStatusChange = useCallback(
    async (e) => {
      const newStatus = e.target.value;
      setJobStatus(newStatus);
      await onUpdateProgress(generationId, activeStep, newStatus);
      setSnackbar({
        open: true,
        message: "Status updated successfully!",
        severity: "success",
      });
    },
    [onUpdateProgress, generationId, activeStep, setSnackbar]
  );

  useEffect(() => {
    setActiveStep(progress);
    setJobStatus(status);
  }, [progress, status]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="job-details-dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="job-details-dialog">
        Job Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignItems="center">
            <Avatar
              src={companyLogo}
              alt={`${companyName} Logo`}
              className={classes.avatar}
            >
              <BusinessIcon />
            </Avatar>
            <Typography variant="h6" style={{ marginLeft: 8 }}>
              {companyName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {jobTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {`Generated on: ${parseTimestamp(
                timestamp
              ).toLocaleDateString()}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {salary && salary !== "" && (
              <Chip
                label={`Salary: ${salary}`}
                className={classes.chip}
                icon={<MoneyIcon />}
              />
            )}
            {jobDetails.jobLocation && (
              <Chip
                label={`Location: ${jobDetails.jobLocation}`}
                className={classes.chip}
              />
            )}
            {jobDetails.jobType && (
              <Chip
                label={`Job Type: ${jobDetails.jobType}`}
                className={classes.chip}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Job Description:
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
              {jobDetails.jobDescription}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: theme.spacing(2, 0) }} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Update Progress:
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleStepChange(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className={classes.radioGroupCentered}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={jobStatus}
              onChange={handleStatusChange}
            >
              <FormControlLabel
                value="active"
                control={<Radio color="primary" />}
                label="Active"
              />
              <FormControlLabel
                value="closed"
                control={<Radio style={{ color: "#f44336" }} />}
                label="Not Selected"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteConfirmationDialog({
  open,
  onClose,
  generations,
  selectedForDeletion,
  onDeleteGeneration,
  setDeleteDialogOpen,
  setIsDeletionMode,
  setSelectedForDeletion,
}) {
  const handleDelete = () => {
    selectedForDeletion.forEach((id) => onDeleteGeneration(id));
    setDeleteDialogOpen(false);
    setIsDeletionMode(false);
    setSelectedForDeletion([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
    >
      <DialogTitle id="delete-confirmation-dialog">
        Delete Job Materials
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Are you sure you want to delete the selected job materials?
        </Typography>
        <List>
          {selectedForDeletion.map((id) => {
            const generation = generations.find((gen) => gen.id === id);
            return (
              <ListItem key={id}>
                <ListItemText
                  primary={generation?.companyName || "Unknown Company"}
                  secondary={generation?.jobTitle || "Unknown Title"}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

GenerationsNavBar.propTypes = {
  generations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      jobDetails: PropTypes.object.isRequired,
      timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      progress: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  onSelectGeneration: PropTypes.func.isRequired,
  selectedGenerationId: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  onDeleteGeneration: PropTypes.func.isRequired,
  selectedApplicationMaterial: PropTypes.string,
  onSelectApplicationMaterial: PropTypes.func.isRequired,
  onEditGeneration: PropTypes.func.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
};

GenerationListItem.propTypes = {
  generation: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDeletionMode: PropTypes.bool.isRequired,
  selectedForDeletion: PropTypes.array.isRequired,
  handleGenerationClick: PropTypes.func.isRequired,
  handleToggleSelection: PropTypes.func.isRequired,
  onSelectGeneration: PropTypes.func.isRequired,
  onSelectApplicationMaterial: PropTypes.func.isRequired,
  selectedApplicationMaterial: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  generations: PropTypes.array.isRequired,
  selectedForDeletion: PropTypes.array.isRequired,
  onDeleteGeneration: PropTypes.func.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  setIsDeletionMode: PropTypes.func.isRequired,
  setSelectedForDeletion: PropTypes.func.isRequired,
};

JobDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  jobDetails: PropTypes.shape({
    applicationDeadline: PropTypes.string,
    companyName: PropTypes.string,
    jobDescription: PropTypes.string,
    jobLocation: PropTypes.string,
    jobTitle: PropTypes.string,
    jobType: PropTypes.string,
    salary: PropTypes.string,
  }).isRequired,
  salary: PropTypes.string,
  companyLogo: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  generationId: PropTypes.string.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

export default GenerationsNavBar;
