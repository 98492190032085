import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormContext } from "./FormContext";
import { generateApplicationMaterials } from "./apiService";
import { useAuth } from "../util/auth";
import { useRouter } from "../util/router";
import { db } from "../util/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "inline-block",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  button: {
    minWidth: 150,
    height: 56,
    borderRadius: 28,
    textTransform: "none",
    fontSize: "1.1rem",
    fontWeight: 600,
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  loadingIndicator: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
}));

const GenerateApplicationMaterialsButton = ({
  onContentGenerated,
  onGenerateStart,
}) => {
  const classes = useStyles();
  const { formData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const isMounted = useRef(true);
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          const credits = Number(data.credits) || 0;
          setUserCredits(credits);
        } else {
          await setDoc(userDocRef, { credits: 5, isSubscribed: false });
          setUserCredits(5);
        }
      }
    };

    fetchUserCredits();
  }, [auth.user]);

  const handleGenerateContent = useCallback(async () => {
    if (!isMounted.current) return;

    if (userCredits <= 0) {
      router.push("/pricing");
      return;
    }

    setIsLoading(true);
    onGenerateStart();

    try {
      const targetJobs = formData.targetJobInformation || [];
      const results = await generateApplicationMaterials(formData, targetJobs);

      // The `results` here is assumed to be an array or object of generated materials.
      // If multiple target jobs, results might be an array. If single job, it might be an object.
      // Normalize to array of objects (one per job):
      const normalizedResults = Array.isArray(results) ? results : [results];

      // Call the new keywords parsing function for each target job
      const keywordsResponses = await Promise.all(
        targetJobs.map(async (job, index) => {
          const response = await fetch(
            "https://us-central1-resumerevivalapp.cloudfunctions.net/process_job_keywords_dev",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                jobDescription: job.jobDescription || "",
              }),
            }
          );

          if (!response.ok) {
            console.error(
              "Failed to parse keywords for job description:",
              job.jobDescription
            );
            return [];
          }

          const data = await response.json();
          // console.log("Keywords for job:", job.jobTitle, data.keywords);
          return data.keywords || [];
        })
      );

      // console.log("All extracted keywords:", keywordsResponses);

      // Merge keywords into the results
      normalizedResults.forEach((res, idx) => {
        res.keywords = keywordsResponses[idx] || [];
      });

      if (isMounted.current) {
        // Deduct credits based on generated materials
        if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const materialsGenerated = normalizedResults.reduce(
            (total, genContent) => {
              const materialTypes = Object.keys(genContent).filter(
                (type) =>
                  !type.endsWith("Json") &&
                  !type.endsWith("Errors") &&
                  type !== "keywords" &&
                  genContent[type]
              );
              return total + materialTypes.length;
            },
            0
          );

          const newCredits = userCredits - materialsGenerated;

          if (newCredits < 0) {
            alert(
              "You do not have enough credits to generate all the selected materials."
            );
            setIsLoading(false);
            return;
          }

          await updateDoc(userDocRef, { credits: newCredits });
          setUserCredits(newCredits);
        }

        // Pass the final normalized results up for handling
        onContentGenerated(normalizedResults);
      }
    } catch (error) {
      console.error("Error calling generateApplicationMaterials:", error);
      if (isMounted.current) {
        alert(
          "An error occurred while generating application materials. Please try again."
        );
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }, [
    auth.user,
    formData,
    onContentGenerated,
    onGenerateStart,
    router,
    userCredits,
  ]);

  return (
    <div className={classes.buttonWrapper}>
      {userCredits > 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateContent}
          disabled={isLoading}
          className={classes.button}
          aria-label={
            isLoading
              ? "Generating application materials"
              : "Generate application materials"
          }
          startIcon={
            isLoading && (
              <CircularProgress
                size={20}
                className={classes.loadingIndicator}
              />
            )
          }
        >
          <Typography variant="button" className={classes.buttonText}>
            {isLoading ? "Generating..." : "Generate Application Materials"}
          </Typography>
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => router.push("/pricing")}
          className={classes.button}
          aria-label="Upgrade Plan"
        >
          <Typography variant="button" className={classes.buttonText}>
            Upgrade Plan
          </Typography>
        </Button>
      )}
    </div>
  );
};

GenerateApplicationMaterialsButton.propTypes = {
  onContentGenerated: PropTypes.func.isRequired,
  onGenerateStart: PropTypes.func.isRequired,
};

export default React.memo(GenerateApplicationMaterialsButton);
