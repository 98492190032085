// src/components/ATSAnalysis.jsx

import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Divider,
  IconButton,
  TextField,
  useTheme,
} from "@material-ui/core";
import { InfoOutlined, DeleteOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const subtleShadow = "0 1px 4px rgba(0,0,0,0.1)";
  const isDark = theme.palette.type === "dark";
  const backgroundColor = isDark
    ? theme.palette.grey[900]
    : theme.palette.background.paper;

  return {
    container: {
      padding: theme.spacing(2, 3),
      marginTop: theme.spacing(2),
      backgroundColor,
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow: subtleShadow,
      opacity: 0,
      overflowY: "auto",
      animation: "$fadeIn 0.4s ease-out forwards",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0 4px 14px rgba(0,0,0,0.15)",
      },
      position: "relative",
      width: "100%", // Ensure it takes full width of its Flex container
    },
    "@keyframes fadeIn": {
      to: {
        opacity: 1,
      },
    },
    titleContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontWeight: 700,
      fontSize: "1.5rem",
      color: isDark ? theme.palette.common.white : theme.palette.primary.main,
      marginRight: theme.spacing(0.5),
    },
    infoIcon: {
      color: isDark ? theme.palette.grey[300] : theme.palette.text.secondary,
      fontSize: "1rem",
      transition: "color 0.3s ease",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    description: {
      textAlign: "center",
      color: theme.palette.text.secondary,
      margin: "0 auto",
      marginBottom: theme.spacing(2),
      maxWidth: "100%", // Utilize full width
      fontSize: "0.875rem",
    },
    sectionTitle: {
      fontWeight: 700,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
      color: theme.palette.text.primary,
      textAlign: "left",
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      paddingLeft: theme.spacing(0.5),
      fontSize: "1rem",
    },
    keywordList: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipBase: {
      borderRadius: theme.shape.borderRadius * 2,
      transition: "transform 0.2s, box-shadow 0.2s",
      "&:hover": {
        transform: "translateY(-1px)",
        boxShadow: subtleShadow,
      },
      fontSize: "0.75rem",
      padding: theme.spacing(0.3, 1),
      fontWeight: 500,
    },
    chipNotFound: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.getContrastText(theme.palette.warning.main),
    },
    chipRememberedNotFound: {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.getContrastText(theme.palette.warning.dark),
    },
    chipFound: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.getContrastText(theme.palette.success.main),
    },
    statsText: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(0.5),
      fontSize: "0.8rem",
    },
    progressContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    scoreGrade: {
      fontWeight: 700,
      marginBottom: theme.spacing(0.5),
      color: theme.palette.text.primary,
      fontSize: "0.9rem",
    },
    button: {
      borderRadius: theme.shape.borderRadius * 2,
      textTransform: "none",
      boxShadow: "none",
      transition: "box-shadow 0.3s ease, background-color 0.3s ease",
      "&:hover": {
        boxShadow: "0 2px 8px rgba(0,0,0,0.12)",
      },
      fontWeight: 600,
      padding: theme.spacing(0.5, 1.5),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: "0.875rem",
    },
    dialogPaper: {
      borderRadius: theme.shape.borderRadius * 2,
      padding: theme.spacing(0.5, 1.5),
      maxWidth: 600, // Increased for better content display
      width: "90%",
    },
    dialogActionButton: {
      borderRadius: theme.shape.borderRadius * 2,
      textTransform: "none",
      fontWeight: 600,
      fontSize: "0.875rem",
    },
    tooltip: {
      fontSize: "0.75rem",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    subtleNote: {
      textAlign: "center",
      marginTop: theme.spacing(1.5),
      fontStyle: "italic",
      color: theme.palette.text.secondary,
      fontSize: "0.7rem",
    },
    progressHigh: {
      "& .MuiLinearProgress-bar": {
        backgroundColor: theme.palette.success.main,
      },
    },
    progressLow: {
      "& .MuiLinearProgress-bar": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    deleteIconButton: {
      marginLeft: theme.spacing(0.25),
      padding: 2,
      "&:hover": {
        color: theme.palette.error.main,
      },
    },
    buttonGroup: {
      display: "flex",
      gap: theme.spacing(0.5),
      flexWrap: "wrap",
      marginTop: theme.spacing(1.5),
      alignItems: "center", // keep checkbox aligned
    },
  };
});

const ATSAnalysis = React.memo(function ATSAnalysis({
  keywords,
  foundKeywords,
  notFoundKeywords,
  rememberedKeywords,
  rememberedNotFoundKeywords,
  totalKeywords,
  foundCount,
  foundRatio,
  scoreLabel,
  onAddKeywordsToSkills,
  onAddRememberedKeyword,
  onRemoveRememberedKeyword,
  onAddMultipleRememberedKeywords,
}) {
  const classes = useStyles();
  const theme = useTheme();

  // State for selecting missing keywords
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const [selectedKeywordsToAdd, setSelectedKeywordsToAdd] = useState([]);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  // State for managing remembered keywords
  const [openManageDialog, setOpenManageDialog] = useState(false);
  const [newRememberedKeyword, setNewRememberedKeyword] = useState("");
  const [addToRememberedChecked, setAddToRememberedChecked] = useState(true);

  // State for auto quick-fill setting, persisted to localStorage
  const [autoQuickFill, setAutoQuickFill] = useState(() => {
    return localStorage.getItem("autoQuickFill") === "true";
  });

  // Persist autoQuickFill changes in localStorage
  useEffect(() => {
    localStorage.setItem("autoQuickFill", autoQuickFill);
  }, [autoQuickFill]);

  const formattedFoundRatio = useMemo(
    () => foundRatio.toFixed(2),
    [foundRatio]
  );
  const missingCount = useMemo(
    () => totalKeywords - foundCount,
    [totalKeywords, foundCount]
  );

  const progressClass = useMemo(
    () => (foundRatio >= 80 ? classes.progressHigh : classes.progressLow),
    [foundRatio, classes.progressHigh, classes.progressLow]
  );

  const hasNotFound = notFoundKeywords && notFoundKeywords.length > 0;
  const hasRememberedNotFound = rememberedNotFoundKeywords.length > 0;

  // Automatically quick-fill remembered keywords if user opts in
  useEffect(() => {
    if (autoQuickFill && hasRememberedNotFound) {
      handleQuickFillRemembered();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoQuickFill, hasRememberedNotFound]);

  // OPEN/CLOSE missing keyword select dialog
  const handleOpenSelectDialog = useCallback(() => {
    setSelectedKeywordsToAdd([]);
    setAddToRememberedChecked(true); // default checked
    setOpenSelectDialog(true);
  }, []);

  const handleCloseSelectDialog = useCallback(() => {
    setOpenSelectDialog(false);
  }, []);

  const handleToggleKeyword = useCallback((keyword) => {
    setSelectedKeywordsToAdd((prev) =>
      prev.includes(keyword)
        ? prev.filter((k) => k !== keyword)
        : [...prev, keyword]
    );
  }, []);

  const handleAddSelectedKeywordsToSkills = useCallback(async () => {
    onAddKeywordsToSkills(selectedKeywordsToAdd);
    if (addToRememberedChecked && selectedKeywordsToAdd.length > 0) {
      const newToRemember = selectedKeywordsToAdd.filter(
        (kw) =>
          !rememberedKeywords
            .map((r) => r.toLowerCase())
            .includes(kw.toLowerCase())
      );
      if (newToRemember.length > 0) {
        await onAddMultipleRememberedKeywords(newToRemember);
      }
    }
    setOpenSelectDialog(false);
  }, [
    onAddKeywordsToSkills,
    onAddMultipleRememberedKeywords,
    selectedKeywordsToAdd,
    addToRememberedChecked,
    rememberedKeywords,
  ]);

  // OPEN/CLOSE info dialog
  const handleOpenInfoDialog = useCallback(() => {
    setOpenInfoDialog(true);
  }, []);

  const handleCloseInfoDialog = useCallback(() => {
    setOpenInfoDialog(false);
  }, []);

  // Manage remembered keywords dialog
  const handleOpenManageDialog = useCallback(() => {
    setOpenManageDialog(true);
  }, []);

  const handleCloseManageDialog = useCallback(() => {
    setOpenManageDialog(false);
  }, []);

  const handleAddRemembered = useCallback(() => {
    const trimmed = newRememberedKeyword.trim();
    if (trimmed) {
      onAddRememberedKeyword(trimmed);
      setNewRememberedKeyword("");
    }
  }, [newRememberedKeyword, onAddRememberedKeyword]);

  const handleRemoveRemembered = useCallback(
    (keyword) => {
      onRemoveRememberedKeyword(keyword);
    },
    [onRemoveRememberedKeyword]
  );

  // Quick fill remembered keywords
  const handleQuickFillRemembered = useCallback(() => {
    if (hasRememberedNotFound) {
      onAddKeywordsToSkills(rememberedNotFoundKeywords);
    }
  }, [
    hasRememberedNotFound,
    rememberedNotFoundKeywords,
    onAddKeywordsToSkills,
  ]);

  return (
    <Card className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>ATS Analysis</Typography>
        <Tooltip
          title="Click to learn more about ATS software and scoring."
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton onClick={handleOpenInfoDialog} style={{ padding: 2 }}>
            <InfoOutlined className={classes.infoIcon} />
          </IconButton>
        </Tooltip>
      </Box>

      <CardContent>
        <Typography className={classes.description}>
          We’ve identified key terms from the job description and checked if
          your resume includes them. Incorporating more of these relevant terms
          may improve your visibility in Applicant Tracking Systems.
        </Typography>

        <Divider className={classes.divider} />

        {/* ATS Score/Bar Section */}
        <Typography className={classes.statsText}>
          Total Keywords: {totalKeywords} | Found: {foundCount} | Missing:{" "}
          {missingCount}
        </Typography>

        <Box className={classes.progressContainer}>
          <Typography className={classes.scoreGrade}>
            ATS Keyword Match Score: {formattedFoundRatio}% ({scoreLabel})
          </Typography>
          <LinearProgress
            variant="determinate"
            value={foundRatio}
            className={progressClass}
            style={{ borderRadius: 2, height: 6 }}
          />
        </Box>

        {foundRatio < 50 && (
          <Typography color="textSecondary" variant="body2">
            Try adding more role-specific keywords from the job description to
            improve your score.
          </Typography>
        )}
        {foundRatio >= 50 && foundRatio < 80 && (
          <Typography color="textSecondary" variant="body2">
            Your score is decent. Consider including a few more critical
            keywords to stand out.
          </Typography>
        )}
        {foundRatio >= 80 && (
          <Typography color="textSecondary" variant="body2">
            Excellent work! Your resume already includes most important
            keywords.
          </Typography>
        )}

        <Typography className={classes.subtleNote}>
          Remember: Authenticity is key. Only add keywords that reflect your
          true skills and experience.
        </Typography>

        <Divider className={classes.divider} />

        {/* Missing Keywords + Quick Fill Section */}
        <Typography className={classes.sectionTitle}>
          Keywords Not Found in Your Resume
        </Typography>
        {hasNotFound ? (
          <>
            <Box className={classes.keywordList}>
              {notFoundKeywords.map((kw, i) => {
                const isRemembered = rememberedNotFoundKeywords
                  .map((r) => r.toLowerCase())
                  .includes(kw.toLowerCase());
                const displayLabel = isRemembered ? `${kw} ★` : kw;
                const chipClass = isRemembered
                  ? `${classes.chipBase} ${classes.chipRememberedNotFound}`
                  : `${classes.chipBase} ${classes.chipNotFound}`;
                return (
                  <Tooltip
                    key={i}
                    title={
                      isRemembered
                        ? "This keyword is remembered. Quickly add it!"
                        : "This keyword was not found in your resume."
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Chip
                      label={displayLabel}
                      className={chipClass}
                      variant="outlined"
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </>
        ) : (
          <Typography
            color="textSecondary"
            style={{ marginBottom: theme.spacing(1.5) }}
            variant="body2"
          >
            All identified keywords appear in your resume.
          </Typography>
        )}

        <Box className={classes.buttonGroup}>
          <Tooltip
            title="Select which missing keywords you’re experienced with and add them to your Skills section."
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleOpenSelectDialog}
              disabled={!hasNotFound}
            >
              Add Missing Keywords to My Skills
            </Button>
          </Tooltip>

          {/* Only show the quick-fill button if there are remembered not found keywords */}
          {hasRememberedNotFound && (
            <Tooltip
              title="Quickly add all remembered missing keywords to your Skills."
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                variant="contained"
                className={classes.button}
                style={{ backgroundColor: theme.palette.success.main }}
                onClick={handleQuickFillRemembered}
              >
                Remembered Keywords Quick-fill
              </Button>
            </Tooltip>
          )}

          {/* Wrap the auto checkbox with a tooltip to explain its behavior */}
          <Tooltip
            title="When enabled, Resume Revival automatically adds your remembered keywords whenever it detects missing ones in your resume."
            classes={{ tooltip: classes.tooltip }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoQuickFill}
                  onChange={(e) => setAutoQuickFill(e.target.checked)}
                  color="primary"
                />
              }
              label="Auto Quick-Fill"
            />
          </Tooltip>
        </Box>

        <Divider className={classes.divider} />

        {/* Keywords Found Section */}
        <Typography className={classes.sectionTitle}>
          Keywords Found in Your Resume
        </Typography>
        {foundKeywords.length > 0 ? (
          <Box className={classes.keywordList}>
            {foundKeywords.map((kw, i) => (
              <Chip
                key={i}
                label={kw}
                className={`${classes.chipBase} ${classes.chipFound}`}
                variant="outlined"
              />
            ))}
          </Box>
        ) : (
          <Typography
            color="textSecondary"
            style={{ marginBottom: theme.spacing(1.5) }}
            variant="body2"
          >
            No extracted keywords were found in your resume. Consider adding
            role-specific terms from the job description.
          </Typography>
        )}

        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleOpenManageDialog}
            style={{
              textTransform: "none",
              borderRadius: 20,
              fontSize: "0.875rem",
            }}
          >
            Manage Remembered Keywords
          </Button>
        </Box>
      </CardContent>

      {/* Dialog for selecting missing keywords */}
      <Dialog
        open={openSelectDialog}
        onClose={handleCloseSelectDialog}
        aria-labelledby="select-missing-keywords-dialog"
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle id="select-missing-keywords-dialog">
          Select Missing Keywords
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom color="textSecondary" variant="body2">
            Here are the keywords not currently in your resume. Add only those
            you genuinely have experience with:
          </Typography>
          <FormControl component="fieldset">
            <FormGroup>
              {notFoundKeywords.map((keyword, index) => {
                const isRemembered = rememberedNotFoundKeywords
                  .map((r) => r.toLowerCase())
                  .includes(keyword.toLowerCase());
                const label = isRemembered ? `${keyword} ★` : keyword;
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedKeywordsToAdd.includes(keyword)}
                        onChange={() => handleToggleKeyword(keyword)}
                        color="primary"
                      />
                    }
                    label={label}
                    style={{ fontSize: "0.8rem" }}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: theme.spacing(1) }}
          >
            Selecting only genuinely relevant keywords ensures an accurate,
            authentic resume.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={addToRememberedChecked}
                onChange={(e) => setAddToRememberedChecked(e.target.checked)}
                color="primary"
              />
            }
            label="Also add these keywords to my remembered keywords (Recommended)"
            style={{ marginTop: theme.spacing(1) }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSelectDialog}
            color="default"
            className={classes.dialogActionButton}
          >
            Cancel
          </Button>
          <Tooltip
            title="These selected keywords will be added to your Skills section."
            classes={{ tooltip: classes.tooltip }}
          >
            <span>
              <Button
                onClick={handleAddSelectedKeywordsToSkills}
                color="primary"
                variant="contained"
                className={classes.dialogActionButton}
                disabled={selectedKeywordsToAdd.length === 0}
              >
                Add Selected Keywords
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      {/* Manage Remembered Keywords Dialog */}
      <Dialog
        open={openManageDialog}
        onClose={handleCloseManageDialog}
        aria-labelledby="manage-remembered-keywords-dialog"
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle id="manage-remembered-keywords-dialog">
          Manage Remembered Keywords
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Remembered keywords are terms you want to keep track of for future
            resumes. You can add new remembered keywords, or remove those you no
            longer want to track.
          </Typography>
          <Box mt={1} display="flex" alignItems="center" gap={1}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Add a new remembered keyword"
              value={newRememberedKeyword}
              onChange={(e) => setNewRememberedKeyword(e.target.value)}
              style={{ flex: 1 }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddRemembered();
                }
              }}
              InputProps={{
                style: { fontSize: "0.8rem" },
              }}
            />
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleAddRemembered}
              disabled={!newRememberedKeyword.trim()}
            >
              Add
            </Button>
          </Box>
          {rememberedKeywords.length > 0 && (
            <>
              <Box
                className={classes.keywordList}
                style={{ marginTop: theme.spacing(1) }}
              >
                {rememberedKeywords.map((kw, i) => (
                  <Box key={i} display="flex" alignItems="center">
                    <Chip
                      label={kw}
                      className={`${classes.chipBase}`}
                      variant="outlined"
                    />
                    <IconButton
                      aria-label="delete remembered keyword"
                      className={classes.deleteIconButton}
                      onClick={() => handleRemoveRemembered(kw)}
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseManageDialog}
            color="primary"
            variant="contained"
            className={classes.dialogActionButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Info Dialog for ATS Explanation */}
      <Dialog
        open={openInfoDialog}
        onClose={handleCloseInfoDialog}
        aria-labelledby="ats-info-dialog"
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle id="ats-info-dialog">About ATS and Your Score</DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant="body2" color="textPrimary">
            Applicant Tracking Systems (ATS) are tools employers use to quickly
            scan and evaluate resumes by identifying key terms related to the
            role. The idea is to ensure your resume includes relevant skills and
            experiences that match the job description.
          </Typography>
          <Typography gutterBottom variant="body2" color="textPrimary">
            The ATS Match Score is an approximation of how closely your resume
            aligns with these keywords.
          </Typography>
          <Typography gutterBottom variant="body2" color="textPrimary">
            We break down the score into ranges:
          </Typography>
          <ul
            style={{
              color: theme.palette.text.primary,
              marginTop: 0,
              paddingLeft: theme.spacing(2),
            }}
          >
            <li>
              <strong>Less than 50%:</strong> Your resume may not be capturing
              enough key terms. Consider adding more relevant keywords to stand
              out.
            </li>
            <li>
              <strong>50% - 79%:</strong> This is a decent score, but there’s
              still room for improvement. Adding a few more critical skills or
              industry terms could enhance your chances.
            </li>
            <li>
              <strong>80% and above:</strong> An excellent score. You’ve
              included most of the relevant keywords, which should help you pass
              through initial ATS screenings.
            </li>
          </ul>
          <Typography gutterBottom variant="body2" color="textPrimary">
            It’s uncommon to reach 100%, and it’s not expected. Every job
            description is unique, and what’s most important is that your resume
            authentically reflects your capabilities. Aim for a higher score,
            but don’t feel discouraged if you don’t hit 100%.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseInfoDialog}
            color="primary"
            variant="contained"
            className={classes.dialogActionButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
});

ATSAnalysis.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  foundKeywords: PropTypes.arrayOf(PropTypes.string),
  notFoundKeywords: PropTypes.arrayOf(PropTypes.string),
  rememberedKeywords: PropTypes.arrayOf(PropTypes.string),
  rememberedNotFoundKeywords: PropTypes.arrayOf(PropTypes.string),
  totalKeywords: PropTypes.number.isRequired,
  foundCount: PropTypes.number.isRequired,
  foundRatio: PropTypes.number.isRequired,
  scoreLabel: PropTypes.string.isRequired,
  onAddKeywordsToSkills: PropTypes.func.isRequired,
  onAddRememberedKeyword: PropTypes.func.isRequired,
  onRemoveRememberedKeyword: PropTypes.func.isRequired,
  onAddMultipleRememberedKeywords: PropTypes.func.isRequired,
};

ATSAnalysis.defaultProps = {
  keywords: [],
  foundKeywords: [],
  notFoundKeywords: [],
  rememberedKeywords: [],
  rememberedNotFoundKeywords: [],
};

export default ATSAnalysis;
