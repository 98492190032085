// PreviewResume.js

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  Link,
} from "@material-ui/core";
import {
  Person as PersonIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  Star as StarIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationOnIcon,
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Language as LanguageIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.background.default,
    fontFamily: "'Roboto', sans-serif",
    color: theme.palette.text.primary,
    maxWidth: 1000,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  name: {
    fontWeight: 700,
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    flexWrap: "wrap",
    justifyContent: "center",
  },
  contactIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  sectionIcon: {
    marginRight: theme.spacing(1),
  },
  previewText: {
    marginBottom: theme.spacing(1),
    whiteSpace: "pre-wrap",
  },
  highlight: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.primary.main : "#fffa8b",
    padding: "0px 2px",
    borderRadius: "2px",
  },
  listItem: {
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "outside",
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
    marginRight: theme.spacing(1),
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(0.5),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    backgroundColor: theme.palette.divider,
  },
}));

// Helper function to escape regex characters
const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

// Helper function to highlight keywords in a text string
const HighlightedText = ({ text, keywords, classes }) => {
  if (!text || !keywords || keywords.length === 0) return text;

  const escapedKeywords = keywords.map((kw) => escapeRegExp(kw));
  const pattern = new RegExp(`\\b(${escapedKeywords.join("|")})\\b`, "gi");

  const parts = [];
  let lastIndex = 0;

  text.replace(pattern, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push(text.slice(lastIndex, offset));
    }
    parts.push(
      <span key={offset} className={classes.highlight}>
        {text.slice(offset, offset + match.length)}
      </span>
    );
    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return <>{parts}</>;
};

HighlightedText.propTypes = {
  text: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object.isRequired,
};

// Sub-components

const Header = ({
  fullName,
  email,
  phone,
  address,
  linkedinUrl,
  githubUrl,
  portfolioUrl,
  classes,
  keywords,
}) => (
  <Box className={classes.header}>
    <Typography variant="h3" className={classes.name}>
      <HighlightedText
        text={fullName || "Name Not Provided"}
        keywords={keywords}
        classes={classes}
      />
    </Typography>
    <Box mt={1} className={classes.contactInfo}>
      {email && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <EmailIcon className={classes.contactIcon} aria-hidden="true" />
          <Link
            href={`mailto:${email}`}
            className={classes.link}
            aria-label={`Email: ${email}`}
          >
            <HighlightedText
              text={email}
              keywords={keywords}
              classes={classes}
            />
          </Link>
        </Box>
      )}
      {phone && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <PhoneIcon className={classes.contactIcon} aria-hidden="true" />
          <Typography>
            <HighlightedText
              text={phone}
              keywords={keywords}
              classes={classes}
            />
          </Typography>
        </Box>
      )}
      {address && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <LocationOnIcon className={classes.contactIcon} aria-hidden="true" />
          <Typography>
            <HighlightedText
              text={address}
              keywords={keywords}
              classes={classes}
            />
          </Typography>
        </Box>
      )}
      {linkedinUrl && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <LinkedInIcon className={classes.linkIcon} aria-hidden="true" />
          <Link
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            aria-label="LinkedIn Profile"
          >
            LinkedIn
          </Link>
        </Box>
      )}
      {githubUrl && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <GitHubIcon className={classes.linkIcon} aria-hidden="true" />
          <Link
            href={githubUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            aria-label="GitHub Profile"
          >
            GitHub
          </Link>
        </Box>
      )}
      {portfolioUrl && (
        <Box display="flex" alignItems="center" mr={2} mb={1}>
          <LanguageIcon className={classes.linkIcon} aria-hidden="true" />
          <Link
            href={portfolioUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            aria-label="Portfolio Website"
          >
            Portfolio
          </Link>
        </Box>
      )}
    </Box>
  </Box>
);

Header.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  linkedinUrl: PropTypes.string,
  githubUrl: PropTypes.string,
  portfolioUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

const Section = ({ title, Icon, children, classes }) => (
  <Box className={classes.section}>
    <Box className={classes.sectionTitle}>
      <Icon className={classes.sectionIcon} aria-hidden="true" />
      <Typography variant="h5">{title}</Typography>
    </Box>
    {children}
  </Box>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

const WorkExperienceItem = ({ job, index, classes, keywords }) => {
  const {
    jobTitle,
    companyName,
    location,
    startDate,
    endDate,
    responsibilities,
  } = job;

  return (
    <Box key={index} mb={2}>
      {index > 0 && <Divider className={classes.divider} />}
      <Box mt={2}>
        <Typography variant="h6">
          <HighlightedText
            text={`${jobTitle || "Untitled Role"} at ${
              companyName || "Unknown Company"
            }`}
            keywords={keywords}
            classes={classes}
          />
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <HighlightedText
            text={`${location || ""} | ${startDate || ""} - ${
              endDate || "Present"
            }`}
            keywords={keywords}
            classes={classes}
          />
        </Typography>
        {responsibilities && responsibilities.length > 0 && (
          <List>
            {responsibilities
              .filter((resp) => resp && resp.trim())
              .map((resp, idx) => (
                <ListItem key={idx} className={classes.listItem}>
                  <HighlightedText
                    text={resp}
                    keywords={keywords}
                    classes={classes}
                  />
                </ListItem>
              ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

WorkExperienceItem.propTypes = {
  job: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

const EducationItem = ({ edu, index, classes, keywords }) => {
  const { degree, fieldOfStudy, minor, institution, location, graduationDate } =
    edu;

  return (
    <Box key={index} mb={2}>
      {index > 0 && <Divider className={classes.divider} />}
      <Box mt={2}>
        <Typography variant="h6">
          <HighlightedText
            text={`${degree || "Degree not specified"} in ${
              fieldOfStudy || "Field of Study"
            }`}
            keywords={keywords}
            classes={classes}
          />
        </Typography>
        {minor && (
          <Typography variant="subtitle1" color="textSecondary">
            <strong>Minor:</strong>{" "}
            <HighlightedText
              text={minor}
              keywords={keywords}
              classes={classes}
            />
          </Typography>
        )}
        <Typography variant="subtitle1" color="textSecondary">
          <HighlightedText
            text={`${institution || ""} | ${location || ""} | Graduated: ${
              graduationDate || ""
            }`}
            keywords={keywords}
            classes={classes}
          />
        </Typography>
      </Box>
    </Box>
  );
};

EducationItem.propTypes = {
  edu: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

const QualificationItem = ({ qualification, index, classes, keywords }) => {
  const { qualificationType, details } = qualification;

  const renderDetails = () => {
    switch (qualificationType) {
      case "Military Service": {
        const { branch, rank, startDate, endDate, specialTraining } = details;
        return (
          <Box>
            {branch && (
              <Typography className={classes.previewText}>
                <strong>Branch:</strong>{" "}
                <HighlightedText
                  text={branch}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {rank && (
              <Typography className={classes.previewText}>
                <strong>Rank:</strong>{" "}
                <HighlightedText
                  text={rank}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {(startDate || endDate) && (
              <Typography className={classes.previewText}>
                <strong>Service Period:</strong>{" "}
                <HighlightedText
                  text={`${startDate || ""} - ${endDate || "Present"}`}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {specialTraining && (
              <Typography className={classes.previewText}>
                <strong>Special Training/Skills:</strong>{" "}
                <HighlightedText
                  text={specialTraining}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
          </Box>
        );
      }
      case "Certification": {
        const {
          credentialType,
          name,
          issuer,
          dateObtained,
          expirationDate,
          description,
        } = details;
        return (
          <Box>
            {credentialType && (
              <Typography className={classes.previewText}>
                <strong>Type:</strong>{" "}
                <HighlightedText
                  text={credentialType}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {name && (
              <Typography className={classes.previewText}>
                <strong>Name:</strong>{" "}
                <HighlightedText
                  text={name}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {issuer && (
              <Typography className={classes.previewText}>
                <strong>Issuer:</strong>{" "}
                <HighlightedText
                  text={issuer}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {(dateObtained || expirationDate) && (
              <Typography className={classes.previewText}>
                <strong>Validity:</strong>{" "}
                <HighlightedText
                  text={`${dateObtained || ""} | ${expirationDate || ""}`}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {description && (
              <Typography className={classes.previewText}>
                <strong>Description:</strong>{" "}
                <HighlightedText
                  text={description}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
          </Box>
        );
      }
      case "Personal Project": {
        const { projectName, description, skills, outcome, projectUrl } =
          details;
        const skillsStr = skills.filter((s) => s).join(", ");
        return (
          <Box>
            {projectName && (
              <Typography className={classes.previewText}>
                <strong>Project Name:</strong>{" "}
                <HighlightedText
                  text={projectName}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {description && (
              <Typography className={classes.previewText}>
                <strong>Description:</strong>{" "}
                <HighlightedText
                  text={description}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {skillsStr && (
              <Typography className={classes.previewText}>
                <strong>Skills Utilized:</strong>{" "}
                <HighlightedText
                  text={skillsStr}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {outcome && (
              <Typography className={classes.previewText}>
                <strong>Outcome:</strong>{" "}
                <HighlightedText
                  text={outcome}
                  keywords={keywords}
                  classes={classes}
                />
              </Typography>
            )}
            {projectUrl && (
              <Typography className={classes.previewText}>
                <strong>URL:</strong>{" "}
                <Link
                  href={projectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  aria-label={`Project URL: ${projectUrl}`}
                >
                  <HighlightedText
                    text={projectUrl}
                    keywords={keywords}
                    classes={classes}
                  />
                </Link>
              </Typography>
            )}
          </Box>
        );
      }
      default:
        return (
          <Typography className={classes.previewText}>
            Unknown qualification type: {qualificationType}
          </Typography>
        );
    }
  };

  return (
    <Box key={index} mb={2}>
      {index > 0 && <Divider className={classes.divider} />}
      <Box mt={2}>
        <Typography variant="h6">
          <HighlightedText
            text={qualificationType || "Qualification"}
            keywords={keywords}
            classes={classes}
          />
        </Typography>
        <Box mt={1}>{renderDetails()}</Box>
      </Box>
    </Box>
  );
};

QualificationItem.propTypes = {
  qualification: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

// Main Component

const PreviewResume = ({ content, keywords = [] }) => {
  const classes = useStyles();
  const theme = useTheme();

  // Memoize the highlighted content to optimize performance
  const highlightedKeywords = useMemo(() => keywords, [keywords]);

  if (!content || typeof content !== "object") {
    return (
      <Box className={classes.container}>
        <Typography variant="h6" color="error">
          Invalid resume data
        </Typography>
      </Box>
    );
  }

  const {
    personalInformation,
    workExperience,
    education,
    skills,
    additionalQualifications,
  } = content;

  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    linkedinUrl,
    githubUrl,
    portfolioUrl,
    professionalSummary,
  } = personalInformation || {};

  // Combine first and last name
  const fullName = `${firstName || ""} ${lastName || ""}`.trim();

  // Create a comma-separated string of skills
  const skillsStr = (skills || []).filter((s) => s).join(", ");

  return (
    <Box className={classes.container}>
      {/* Header with Name and Contact Information */}
      <Header
        fullName={fullName}
        email={email}
        phone={phone}
        address={address}
        linkedinUrl={linkedinUrl}
        githubUrl={githubUrl}
        portfolioUrl={portfolioUrl}
        classes={classes}
        keywords={highlightedKeywords}
      />

      {/* Professional Summary */}
      <Section title="Professional Summary" Icon={StarIcon} classes={classes}>
        <Typography variant="body1">
          <HighlightedText
            text={professionalSummary?.trim() || "No summary provided."}
            keywords={highlightedKeywords}
            classes={classes}
          />
        </Typography>
      </Section>

      {/* Work Experience */}
      {workExperience && workExperience.length > 0 && (
        <Section title="Work Experience" Icon={WorkIcon} classes={classes}>
          {workExperience.map((job, index) => (
            <WorkExperienceItem
              key={index}
              job={job}
              index={index}
              classes={classes}
              keywords={highlightedKeywords}
            />
          ))}
        </Section>
      )}

      {/* Education */}
      {education && education.length > 0 && (
        <Section title="Education" Icon={SchoolIcon} classes={classes}>
          {education.map((edu, index) => (
            <EducationItem
              key={index}
              edu={edu}
              index={index}
              classes={classes}
              keywords={highlightedKeywords}
            />
          ))}
        </Section>
      )}

      {/* Skills */}
      <Section title="Skills" Icon={StarIcon} classes={classes}>
        {skillsStr ? (
          <Typography variant="body1">
            <HighlightedText
              text={skillsStr}
              keywords={highlightedKeywords}
              classes={classes}
            />
          </Typography>
        ) : (
          <Typography className={classes.previewText}>
            No skills provided.
          </Typography>
        )}
      </Section>

      {/* Additional Qualifications */}
      {additionalQualifications && additionalQualifications.length > 0 && (
        <Section
          title="Additional Qualifications"
          Icon={StarIcon}
          classes={classes}
        >
          {additionalQualifications.map((qualification, index) => (
            <QualificationItem
              key={index}
              qualification={qualification}
              index={index}
              classes={classes}
              keywords={highlightedKeywords}
            />
          ))}
        </Section>
      )}
    </Box>
  );
};

PreviewResume.propTypes = {
  content: PropTypes.shape({
    personalInformation: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.string,
      linkedinUrl: PropTypes.string,
      githubUrl: PropTypes.string,
      portfolioUrl: PropTypes.string,
      professionalSummary: PropTypes.string,
    }),
    workExperience: PropTypes.arrayOf(PropTypes.object),
    education: PropTypes.arrayOf(PropTypes.object),
    skills: PropTypes.arrayOf(PropTypes.string),
    additionalQualifications: PropTypes.arrayOf(PropTypes.object),
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
};

export default PreviewResume;
